import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'
import { checkAccessMiddleware } from './middlewares'

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

router.beforeEach(checkAccessMiddleware)

export default router
