import http from './http'

const DashboardService = {
  getDashboardInfo() {
    const api = '/dashboard'
    return new http(true).get(api)
  },

  getRecentQuizResults() {
    const api = '/dashboard/recent-quiz-results'
    return new http(true).get(api)
  }
}

export default DashboardService
