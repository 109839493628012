<template>
  <div class="bg-gray-100">
    <!-- sidenav bar -->
    <side-bar></side-bar>
    <!-- main content -->
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <!-- header -->
      <Header />
      <!-- router view -->
      <router-view />
      <div
        class="position-fixed bottom-1 end-1 z-index-2"
      >
        <vsud-snackbar
          v-for="item in notifications"
          :key="item.id"
          :title="item.title"
          date=""
          :message="item.message"
          :icon="{ component: 'ni ni-check-bold', color: 'success' }"
          color="white"
          :close-handler="closeSnackbar"
        />
      </div>
    </main>
  </div>
</template>

<script>
// import
import { createNamespacedHelpers } from 'vuex'
import Header from './Header'
import SideBar from './SideBar'
import dashboard from "@/assets/js/soft-ui-dashboard.js"
import VsudSnackbar from "@/components/VsudSnackbar.vue"

const { mapState: mapStateNoti, mapActions: mapActionsNoti } = createNamespacedHelpers('notifications')
// export default
export default {
  components: {
    Header,
    SideBar,
    VsudSnackbar,
  },
  data() {
    return {
      snackbar: 'success',
    };
  },
  computed: {
    ...mapStateNoti(['notifications']),
  },
  mounted() {
    dashboard()
  },
  methods: {
    ...mapActionsNoti(['updateNotifications']),
    closeSnackbar() {
      this.updateNotifications([])
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
