import http from './http'

const QuizService = {
  getQuiz(id) {
    const api = `/quiz-result/${id}`
    return new http(true).get(api, {})
  },
  startQuiz(id) {
    const api = `/quiz/${id}/start`
    return new http(true).post(api)
  },
  finishQuiz(id, answers) {
    const api = `/quiz/${id}/finish`
    return new http(true).post(api, answers)
  }
}

export default QuizService
