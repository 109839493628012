<template>
  <div :class="classInputExtend">
    <input
      v-if="inputType !== 'textarea'"
      :value="modelValue"
      :type="type"
      :name="name"
      :class="['form-control next-focus', className]"
      :maxlength="limitInput"
      :disabled="disabled"
      :placeholder="placeholder"
      :max="max"
      autocomplete="on"
      @input="(event) => $emit('update:modelValue', event.target.value)"
      @blur="onBlur()"
      @focus="onFocus()"
      @focusout="$emit('onFocusOutInput')"
      @keyup.enter="eventEnter($event)"
    >
    <textarea
      v-else
      v-model.trim="modelValue"
      :name="name"
      :placeholder="placeholder"
      :class="['form-control custom-font-size next-focus', className]"
      :maxlength="limitInput"
      :disabled="disabled"
      v-bind="$attrs"
      @blur="onBlur()"
      @focus="onFocus()"
      @keyup.enter="eventEnter($event)"
    />
    <div class="invalid-feedback">
      <span v-if="v$.modelValue.$errors && v$.$dirty">
        <template v-if="v$.modelValue.required.$invalid">
          {{ errorRequired }}
        </template>
        <template v-if="v$.modelValue.email.$invalid">
          {{ errorRequired }}
        </template>
        <template v-else-if="v$.modelValue.minLength.$invalid">
          {{ errorMinLength }}
        </template>
        <template v-else-if="v$.modelValue.maxLength.$invalid">
          {{ errorMaxLength }}
        </template>
        <template v-else-if="v$.modelValue.minValue.$invalid">
          {{ errorMinValue }}
        </template>
        <template v-else-if="v$.modelValue.maxValue.$invalid">
          {{ errorMaxValue }}
        </template>
      </span>
      <template v-else-if="errors">
        {{ errors }}
      </template>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, maxLength, minLength, email } from '@vuelidate/validators'

export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
     modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    className: {
      type: [String, Object, Array],
      default: "",
    },
    classInputExtend: {
      type: [String, Object, Array],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    minLength: {
      type: Number,
      default: undefined,
    },
    maxValue: {
      type: Number,
      default: undefined,
    },
    minValue: {
      type: Number,
      default: undefined,
    },
    limitInput: {
      type: Number,
      default: undefined,
    },
    errorRequired: {
      type: String,
      default: "ss",
    },
    errorMinLength: {
      type: String,
      default: "",
    },
    errorMaxLength: {
      type: String,
      default: "",
    },
    errorMinValue: {
      type: String,
      default: "",
    },
    errorMaxValue: {
      type: String,
      default: "",
    },
    errorsApi: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
  },
  setup (props, context) {
    const updateValue = (event) => {
      context.emit('update:modelValue', event.target.value);
    }

    return {
      v$: useVuelidate(),
      isFocus: false,
      inputType: props.type,
      errors: props.errorsApi,
      updateValue
    }
  },
  computed: {
  },
  watch: {
    modelValue() {
      this.errors = ""
    },
    errorsApi(val) {
      this.errors = val
    },
  },
  validations() {
    return {
      modelValue: {
        required: this.required ? required : true,
        email: this.type === "email" ? email: true,
        maxLength: this.maxLength ? maxLength(this.maxLength) : true,
        minLength: this.minLength ? minLength(this.minLength) : true,
        minValue: (value) => (this.minValue ? value >= this.minValue : true),
        maxValue: (value) => (this.maxValue ? value <= this.maxValue : true),
      },
    }
  },
  methods: {
    validate() {
      this.v$.modelValue.$touch()
      if (!this.v$.modelValue.$invalid) {
        return this.modelValue
      }

      return null
    },
    resetValidate() {
      this.v$.$reset()
      this.resetValue()
    },
    onBlur() {
      this.isFocus = false
      if (this.blurValidate) {
        this.v$.$touch()
      }
    },
    onFocus() {
      this.isFocus = true
    },
    resetValue() {
    },
    eventEnter(event) {
      this.$emit("eventEnter", event)
    },
  },
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}

// input, textarea{
//   border-radius: 0;
// }
</style>
