import auth from './auth'
import user from './user'
import course from './course'
import notifications from './notifications'
import common from './common'
import discussions from './discussions'

export default {
  auth,
  user,
  course,
  notifications,
  common,
  discussions
}
