<template>
  <div class="container-fluid pb-4">
    <div class="mb-4">
      <h2 class="mb-0 page_title">{{ $tc('quiz_result') }}</h2>
    </div>

    <div class="row">
      <div class="col-lg-4 order-lg-2">
        <div class="card -round-12 quiz-time mb-4 mb-lg-0">
          <div class="card-header p-3 p-xxl-4 pb-0 pb-xxl-0">
            <h6 class="fw700 fs-18 mb-0">{{ $tc('result') }}</h6>
          </div>
          <div class="card-body -round-12 p-3 p-xxl-4 pt-2 pt-xxl-2">
            <p class="quiz-time__point mb-3 fw-500 text-center">
              <span>{{dataQuiz.result.grade}}</span>/{{dataQuiz.quiz.max_grade}}
            </p>

            <ul class="quiz-time__text list-unstyled ps-0 fs-16">
              <li class="d-flex justify-content-between mb-3">
                <div class="text-nowrap pe-2">{{ $tc('time_spent') }}:</div>
                <div class="text-end -value">{{dataQuiz.result.time_spent_text}}</div>
              </li>
              <li class="d-flex justify-content-between mb-3">
                <div class="text-nowrap pe-2">{{ $tc('completed_at') }}:</div>
                <div class="text-end -value">{{dataQuiz.result.finish}}</div>
              </li>
              <li class="d-flex justify-content-between mb-3">
                <div class="text-nowrap pe-2">{{ $tc('situation') }}</div>
                <div
                  v-if="dataQuiz.result.summary"
                  :class="['text-end', dataQuiz.result.summary.includes('pass') ? 'passed' : 'failed']"
                >
                  {{dataQuiz.result.summary}}
                </div>
              </li>
            </ul>

            <h6 class="fw700 fs-18 mb-3">{{ $tc('questions') }}</h6>
            <div class="quiz-time__answer">
              <ul class="row row-cols-10 g-2 list-unstyled p-0">
                <li
                   v-for="item in questions"
                  :key="item.id"
                >
                  <div :class="getClassResult(item)">{{ item.key }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-8">
        <div class="card mb-4 mb-lg-0">
          <div class="card-header p-3 p-xxl-4 pb-2 pb-xxl-2">
            <h6 class="fw700 fs-18 mb-0">
              {{ quiz.title }}
            </h6>
          </div>
          <div class="card-body p-3 p-xxl-4 pt-3">
            <ul class="quiz-list p-0 list-unstyled">
              <li
                v-for="(item, index) in questions"
                :key="item.id"
              >
                <h6 class="mb-3">
                  {{index + 1}}. {{item.content}}
                </h6>
                <div>
                  <div
                    class="form-wrap-check d-flex"
                    v-for="(answer, index2) in item.answers"
                    :key="answer.id"
                  >
                    <div class="form-check-result">
                      <i v-if="answer.is_selected && answer.is_true" class="icon-check"></i>
                      <i v-else-if="answer.is_selected" class="icon-close"></i>
                      <i v-else-if="answer.is_true" class="icon-check"></i>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        :type="item.answer_type == 'single_choice' ? 'radio' : 'checkbox'"
                        :checked="answer.is_selected"
                        :name="`answer-${item.id}`"
                        :id="`answer-${answer.id}`"
                        disabled
                      />
                      <label class="" :for="`answer-${answer.id}`">
                        {{getAlp(index2)}}. {{answer.content}}
                      </label>
                    </div>
                  </div>

                    <p class="text-muted fs-14 mt-3 font-italic">
                        {{item.explanation}}
                    </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { get } from 'lodash'
import Footer from '../../layouts/Footer.vue';
import QuizService from '../../services/quiz_service'

const { mapActions: mapActionsCommon } = createNamespacedHelpers('common')

export default {
  components: { Footer },
  props: {},
  data() {
    return {
      dataQuiz: { result: {}, quiz: {} },
      answers: [],
    };
  },
  computed: {
    questions() {
      return get(this.dataQuiz, 'questions', [])
    },
    quiz() {
      return get(this.dataQuiz, 'result.quiz', {})
    },
  },
  watch: {
    dataQuiz(val) {
       if (val.course) {
        this.updateBreadcrumbs([
          { name: this.$tc('all_courses'), link: "/my-course"},
          { name: this.$tc('course_detail'), link: `/courses/${get(this.dataQuiz, 'course.slug')}`},
          { name: this.$tc('quiz'), link: ""},
        ])
      }
    }
  },
  mounted() {
    this.getDataQuiz()
    this.updateBreadcrumbs([{ name: this.$tc('all_courses'), link: "/my-course"}])
  },
  methods: {
    ...mapActionsCommon(['updateBreadcrumbs']),
     async getDataQuiz() {
      const { id } = this.$route.params
      const response = await QuizService.getQuiz(id)
      const data = get(response, 'data')
      if (data.code === 200) {
        this.dataQuiz = data.data
      }
    },
    getAlp(index) {
      const upperCaseAlp = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O",
        "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
      return upperCaseAlp[index]
    },
    getClassResult(question) {
      let result = true
      question.answers.forEach((item) => {
        if (item.is_selected !== item.is_true) {
          result = false
        }
      });
      return result ? '-correct' : '-wrong'
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
