<template>
  <div class="container-fluid pb-4">
    <!-- general info, recent quiz start -->
    <div class="row">
      <div
        class="box-top-count-individual col-xl-6 col-lg-8 col-sm-12 col-12 position-relative z-index-2"
      >
        <!-- title start -->
        <div class="card card-plain mb-4">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <h2 class="mb-0 page_title">{{ $tc('individual_report') }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- title end -->
        <!-- general info start -->
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="card mb-4 box_count_icon">
              <div class="card-body p-3 p-xxl-4">
                <div class="row">
                  <div class="col-8">
                    <div class="numbers">
                      <p class="text-sm mb-1 text-capitalize font-weight-bold">
                        {{ $tc('all_courses') }}
                      </p>
                      <h5 class="count fw700 fs-18">{{ allCoursesNumber }}</h5>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <div
                      class="icon icon-shape bg-primary-cs shadow text-center box-icon-img border-radius-md"
                    >
                      <span class="icon-img"
                        ><img src="images/BookOpen.png" width="" height=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card box_count_icon">
              <div class="card-body p-3 p-xxl-4">
                <div class="row">
                  <div class="col-8">
                    <div class="numbers">
                      <p class="text-sm mb-1 text-capitalize font-weight-bold">
                        {{ $tc('on_going_courses') }}
                      </p>
                      <h5 class="count fw700 fs-18">{{ ongoingCoursesNumber }}</h5>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <div
                      class="icon icon-shape bg-primary-cs shadow text-center border-radius-md box-icon-img"
                    >
                      <span class="icon-img"
                        ><img
                          src="images/BookmarksSimple.png"
                          width=""
                          height=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 mt-sm-0 mt-4">
            <div class="card mb-4 box_count_icon">
              <div class="card-body p-3 p-xxl-4">
                <div class="row">
                  <div class="col-8">
                    <div class="numbers">
                      <p class="text-sm mb-1 text-capitalize font-weight-bold">
                        {{ $tc('pass_rate') }}
                      </p>
                      <h5 class="count fw700 fs-18">{{ passRate }}</h5>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <div
                      class="icon icon-shape bg-primary-cs shadow text-center border-radius-md box-icon-img"
                    >
                      <span class="icon-img"
                        ><img src="images/GraduationCap.png" width="" height=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card box_count_icon">
              <div class="card-body p-3 p-xxl-4">
                <div class="row">
                  <div class="col-8">
                    <div class="numbers">
                      <p class="text-sm mb-1 text-capitalize font-weight-bold">
                        {{ $tc('medals') }}
                      </p>
                      <h5 class="count fw700 fs-18">{{ medalsNumber }}</h5>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <div
                      class="icon icon-shape bg-primary-cs shadow text-center border-radius-md box-icon-img"
                    >
                      <span class="icon-img"
                        ><img src="images/Medal.png" width="" height=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- general info end -->
        <!-- recent quiz start -->
        <div class="row mt-4">
          <div class="col-12 col-lg-12">
            <div class="card">
              <div class="card-header p-3 p-xxl-4 pb-0 pb-xxl-0">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2 fw700 fs-18">
                    {{ $tc('recent_quiz_result') }}
                  </h6>
                </div>
              </div>
              <div class="table-responsive p-3 p-xxl-4 pt-3 pt-xxl-3">
                <table class="table align-items-center table-quiz-result mb-0">
                  <tbody>
                    <tr v-for="(result, index) in recentQuizResults" :key="'result-'+index">
                      <td>
                        <div class="col col_time">
                          <p class="title">{{ $tc('finished') }}:</p>
                          <h6 class="content">{{ result.finish }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="col col_less">
                          <p class="title">{{ $tc('lesson') }}:</p>
                          <h6 class="content">{{ result.lesson }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="col col_spent">
                          <p class="title">{{ $tc('time_spent') }}:</p>
                          <h6 class="content">{{ result.time_spent_text }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="col col_points">
                          <p class="title">{{ $tc('points') }}:</p>
                          <h6 class="content">{{ result.grade }}/{{ result.max_grade }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="col col_status">
                          <p class="title">{{ $tc('summary') }}:</p>
                          <h6 class="content">
                            <span :class="result.summary">{{ $tc(result.summary) }}</span>
                          </h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- recent quiz end -->
      </div>
    </div>
    <!-- general info, recent quiz end -->
    <!-- on-going courses, chart start -->
    <div class="row mt-4">
      <!-- left column start -->
      <div class="col-lg-6 mb-lg-0 mb-4">
        <div class="row individual__count_char">
          <!-- spent hours -->
          <div class="col-xl-4 col-lg-12 col-sm-12 mb-4 individual-time-spent">
            <div class="card h-100">
              <div class="card-body p-3 p-xxl-4">
                <div class="icon icon-shape bg-primary-cs shadow text-center box-icon-img">
                  <span class="icon-img"
                    ><img src="images/ClockAfternoon.png" width="" height=""
                  /></span>
                </div>
                <p class="mb-0 me-3 pt-3 sub_title fs-14 fw-700 ms-auto color-primary-2">{{ $tc('last_login') }}</p>
                <h5 class="mb-0 count_title">
                  <span class="count fw-700 fs-18">{{ lastLogIn }}</span>
                </h5>
              </div>
            </div>
          </div>
          <!-- total courses -->
          <div
            class="col-xl-8 col-lg-12 col-sm-12 mb-4 individual-courses-char"
          >
            <div class="card h-100 overflow-hidden">
              <div class="card-body p-3 p-xxl-4">
                <div class="row">
                  <div class="col-lg-5 col-sm-6 col-5">
                    <div class="d-flex mb-4 pb-2">
                      <div
                        class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                      >
                        <span class="icon-img"
                          ><img src="images/outline.png" width="" height=""
                        /></span>
                      </div>
                      <div class="ms-3">
                        <p
                          class="fs-12 mb-0 text-capitalize fw400 color-primary-2"
                        >
                          {{ $tc('lessons') }}
                        </p>
                        <h5 class="fw700 fs-18 mb-0">{{ allLessonsNumber }}</h5>
                      </div>
                    </div>
                    <span class="badge badge-dot d-block text-start pb-0">
                      <span class="dot-icon dot-completed"></span>
                      <span class="fw500 fs-12 color-primary-1">{{ $tc('completed') }}</span>
                    </span>
                    <span class="badge badge-dot d-block text-start">
                      <span class="dot-icon dot-going"></span>
                      <span class="fw500 fs-12 color-primary-1">{{ $tc('learning') }}</span>
                    </span>
                  </div>
                  <div class="col-lg-7 col-sm-6 col-7 my-auto">
                    <DonutChart :labels="['completed', 'learning']"
                                :dataset="donutDataSet"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- chart -->
          <div class="col-lg-12 col-12">
            <div class="card overflow-hidden">
              <div class="card-header p-3 p-xxl-4 pb-0 pb-xxl-0">
                <div class="d-flex align-items-center">
                  <div
                    class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                  >
                    <span class="icon-img"
                      ><img src="images/circle.png" width="" height=""
                    /></span>
                  </div>
                  <div class="ms-3">
                    <p class="mb-0 fw700 fs-14 color-primary-2">
                        {{ $tc('average_point') }}
                    </p>
                    <h5 class="fw700 fs-18 color-primary-1 mb-0">{{ averagePoints }}</h5>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <!-- <line-chart /> -->
                <line-chart
                  :chartData="chartData"
                  :chartLabels="chartLabels"
                ></line-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- left column end -->
      <!-- right column start -->
      <div class="col-lg-6">
        <div class="card z-index-2 h-100">
          <div class="card-header pb-0 p-3 p-xxl-4 pb-3 pb-xxl-3">
            <div class="">
              <h6 class="mb-0 fw700 fs-18 color-primary-1">{{ $tc('on_going_courses') }}</h6>
            </div>
          </div>
          <div class="card-body p-3 p-xxl-4 pt-0 pt-xxl-0">
            <ul class="list-group">
              <li v-for="(course, index) in ongoingCourses" :key="'ongoing-'+index"
                class="list-group-item border-0 d-flex align-items-center px-0 mb-2"
              >
                <div class="w-100">
                  <div class="d-flex align-items-center mb-2">
                    <span
                      class="me-2 fw500 fs-12 color-primary-2 text-capitalize"
                      >{{ course.name }}</span
                    >
                    <span class="ms-auto fw700 fs-14 color-primary-2">
                      {{ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2}).format(course.completed_lesson_count*100/course.lesson_count) }}%</span>
                  </div>
                  <div>
                    <div class="progress progress-md">
                      <div
                          class="progress-bar bg-pink"
                          style="width: 88%"
                          role="progressbar"
                          :aria-valuenow="course.completed_lesson_count"
                          aria-valuemin="0"
                          :aria-valuemax="course.lesson_count"
                      ></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="d-flex align-items-center info_course">
              <p class="text fw400 fs-14 color-primary-1 mb-0 col-sm-6 col-12">
                &ensp;
              </p>
              <div class="col-sm-6 col-12 bx_btn text-right">
                <a class="mb-0 btn btn-primary-cs" href="/my-course">{{ $tc('view_all_courses') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- right column end -->
    </div>
    <!-- on-going courses, chart end -->
    <!-- globe start -->
    <div class="row">
      <div class="col-12">
        <Globe />
      </div>
    </div>
    <!-- globe end -->
    <!-- footer start -->
    <Footer />
    <!-- footer end -->
  </div>
</template>

<script>
// import componetns
import Footer from '../../layouts/Footer.vue';
import LineChart from  '../../components/LineChart.vue';
import Globe from './components/Globe.vue';
// import services
import DashboardService from "@/services/dashboard_service";
// import libs
import { createNamespacedHelpers } from 'vuex';
import DonutChart from "@/pages/individual-report/components/DonutChart";
const { mapActions } = createNamespacedHelpers('common');

export default {
  components: {DonutChart, Footer, LineChart, Globe },
  props: {},
  data() {
    return {
      allCoursesNumber: 0,
      ongoingCoursesNumber: 0,
      allLessonsNumber: 0,
      ongoingLessonsNumber: 0,

      passRate: null,
      medalsNumber: null,

      averagePoints: 0,
      ongoingCourses: [],

      chartData: [],
      chartLabels: [],
      donutDataSet: [],

      recentQuizResults: [],
      lastLogIn: null,

    };
  },
  computed: {},
  async mounted() {
    this.updateBreadcrumbs([{ name: 'Individual report', link: "/individual-report"}]);

    const response = await DashboardService.getDashboardInfo();
    const data = response.data.data;
    if (response.data.code === 200) {
      this.ongoingCourses = data.ongoing_courses.map((course) => {
        course.completed_lesson_count = course.student_learning_lessons.filter((item) => {return item.status === 'completed'}).length
        return course;
      });

      this.allCoursesNumber = data.count.all_courses;
      this.ongoingCoursesNumber = data.count.ongoing_courses;
      this.ongoingLessonsNumber = data.count.ongoing_lessons;
      this.allLessonsNumber = data.count.all_lessons;
      this.medalsNumber = data.count.medal;
      this.passRate = data.count.pass;
      this.donutDataSet = [data.count.all_lessons - data.count.ongoing_lessons, data.count.ongoing_lessons];
      this.lastLogIn = data.last_logged_in;

      this.chartData = data.chart.data;
      this.chartLabels = data.chart.labels;
      this.averagePoints = data.chart.average;
    }

    const response2 = await DashboardService.getRecentQuizResults();
    this.recentQuizResults = response2.data.data;
  },
  methods: {
    ...mapActions(['updateBreadcrumbs']),
  },
};
</script>

<style lang="scss" scoped>

</style>
