import { createApp } from 'vue'
import VuePlyr from 'vue-plyr'
import Countdown from 'vue3-flip-countdown'
import 'vue-plyr/dist/vue-plyr.css'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/css/nucleo-svg.css'
import './assets/css/nucleo-icons.css'

import './assets/js/plugins/perfect-scrollbar.min.js'
import './assets/js/plugins/smooth-scrollbar.min.js'
import './assets/css/nucleo-svg.css'
import './assets/css/nucleo-icons.css'
import './assets/css/custom-fonts.css'
import './assets/css/soft-ui-dashboard.css?v=1.0.8'
import './assets/css/custom-style.css'
import './assets/css/responsive.css'


createApp(App).use(i18n).use(Countdown).use(VuePlyr, { plyr: {} }).use(router).use(store).mount('#app')
