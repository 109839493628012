import { get } from 'lodash'

import CourseService from '../../services/course_service'

const state = {
  list: []
}

const actions = {
  requestGetList: async ({ commit }, params ) => {
    const response = await CourseService.getList(params)
    const data = get(response, 'data')
    if (data.data) {
      commit('GET_COURSES_SUCCESS', data.data)
    } else {
      commit('GET_COURSES_FAILED')
    }
  },
}

const mutations = {
  ['GET_COURSES_SUCCESS'](state, data) {
    state.list = data
  },
  ['GET_COURSES_FAILED'](state) {
    state.list = []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
