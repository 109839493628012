import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import modules from './modules'

const store = createStore({
  modules,
  plugins: [createPersistedState({
    paths: ['auth']
  })],
  strict: process.env.NODE_ENV === 'development'
});

export default store
