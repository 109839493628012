import http from './http'
const ACCESS_TOKEN = 'access_token';
const ACCOUNT = 'ACCOUNT';

const AuthService = {
  signIn(credentials) {
    const api = '/auth/login'
    return new http().post(api, {
      ...credentials
    })
  },
  requestResetPwd(credentials) {
    const api = '/auth/forgot-password'
    return new http().post(api, {
      ...credentials
    })
  },
  resetPwd(credentials) {
    const api = '/auth/reset-password'
    return new http().post(api, {
      ...credentials
    })
  },
  logout() {
    const api = '/logout'
    return new http(true).delete(api)
  },

  setToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  },

  getToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  },

  getAccount() {
    return localStorage.getItem(ACCESS_TOKEN);
  },

  setAccount(account) {
    localStorage.setItem(ACCOUNT, account);
  },

  removeAccount() {
    localStorage.removeItem(ACCOUNT);
  }
}

export default AuthService
