<template>
  <div class="container-fluid pb-4">
    <div class="mb-4">
      <h2 class="mb-0 page_title">{{ $tc('notifications') }}</h2>
    </div>

    <div class="mx-auto">
      <div class="card">
        <div class="card-body p-3 p-xxl-4 pt-3">
          <ul class="notification-list p-0 list-unstyled" v-if="listNotifications.length > 0">
            <li
              v-for="item in listNotifications"
              :key="item.id"
              class="d-flex"
            >
              <div
                class="icon icon-shape bg-primary-cs shadow text-center box-icon-img border-radius-md me-3"
              >
                <span class="icon-img"
                  ><img src="images/BookOpen.png" width="" height=""
                /></span>
              </div>

              <div class="flex-grow-1 position-relative pe-3">
                <p class="mb-1">
                  <strong class="fw-600">{{ item.sender.name }}</strong> {{item.message}}
                </p>
                <p class="-date fs-12 mb-0">{{ item.created_at }}</p>
                <div
                  class="li--status -read position-absolute top-50 end-0 translate-middle"
                ></div>
              </div>
            </li>
          </ul>
            <div v-else class="p-5 text-center">{{ $tc('you_have_no_notification')}}</div>
        </div>
      </div>
    </div>
    <Footer />

  </div>
</template>

<script>
import Footer from '../../layouts/Footer.vue';
import NotificationService from '@/services/notification_service'
import { createNamespacedHelpers } from 'vuex'
import { get } from 'lodash'

const { mapState, mapActions } = createNamespacedHelpers('notifications')
const { mapActions: mapActionsCommon } = createNamespacedHelpers('common')

export default {
  components: { Footer },
  props: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['listNotifications']),
  },
  mounted() {
    this.requestListNoti()
    this.updateBreadcrumbs([{ name: this.$tc('notifications'), link: "/notification"}])
  },
  methods: {
    ...mapActions(['updateList']),
    ...mapActionsCommon(['updateBreadcrumbs']),
    async requestListNoti() {
      const response = await NotificationService.getList()
      const data = get(response, 'data', {})
      if (data.code === 200) {
        this.updateList(data.data)
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
