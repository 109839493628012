<template>
  <div>
    <footer class="footer pt-3">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="copyright text-center text-lg-start">
            ©
            {{ (new Date()).getFullYear() }}, {{ $tc('made_with') }} <i class="fa fa-heart"></i> by
            <a
              :href="brand.official_website"
              class="copyright-link fw700 color-primary-1"
              target="_blank"
              >{{ brand.brand_name }}</a
            >
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a href="#" class="nav-link">{{ $tc('faq') }}</a>
            </li>
            <li class="nav-item">
              <a href="/imprint" class="nav-link">{{ $tc('imprint') }}</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link">{{ $tc('private') }}</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link">{{ $tc('contact') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ConfigService from "@/services/config_service";

export default {
  name: 'Footer',
  data() {
    return {
      brand: {}
    }
  },
  async mounted() {
    const response = await ConfigService.getBrand();
    if (response.status === 200 && response.data.code === 200) {
      this.brand = response.data.data;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-footer {
  color: #0b0b0c;
  opacity: 0.3;
}
</style>
