// import components
import SignInScreen from '../pages/signin/index.vue'
import HomeScreen from '../pages/home/index.vue'
import QuizScreen from '../pages/quiz/index.vue'
import QuizResultScreen from '../pages/quiz-result/index.vue'
import ProfileScreen from '../pages/profile/index.vue'
import NotificationScreen from '../pages/notification/index.vue'
import MyCourseScreen from '../pages/my-course/index.vue'
import IndividualReportScreen from '../pages/individual-report/index.vue'
import CourseDetailOverview from '../pages/course-detail/index.vue'
import CourseDiscuss from '../pages/course-discuss/CourseDiscuss.vue'
import RequestResetPasswordScreen from '../pages/password/RequestReset.vue'
import ResetPasswordScreen from '../pages/password/index.vue'
import NotFound from '../pages/not-found/NotFound.vue'
import Imprint from "@/pages/imprint/index.vue";
import {VUE_APP_API_HOST} from "@/services/http";

// routes
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeScreen,
    meta: { isAuth: true, title: 'home' }
  },
  {
    path: '/course-discuss/:courseSlug',
    name: 'course-discuss',
    component: CourseDiscuss,
    meta: { isAuth: true, title: 'course-discuss' }
  },
  {
    path: '/courses/:id',
    component: CourseDetailOverview,
    meta: { isAuth: true, title: 'course-detail-overview' }
  },
  {
    path: '/my-course',
    name: 'my-course',
    component: MyCourseScreen,
    meta: { isAuth: true, title: 'my-course' }
  },
  {
    path: '/individual-report',
    name: 'individual-report',
    component: IndividualReportScreen,
    meta: { isAuth: true, title: 'individual-report' }
  },
  {
    path: '/notification',
    name: 'notification',
    component: NotificationScreen,
    meta: { isAuth: true, title: 'notification' }
  },
  {
    path: '/quiz/:id',
    component: QuizScreen,
    meta: { isAuth: true, title: 'quiz' }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileScreen,
    meta: { isAuth: true, title: 'profile' }
  },
  {
    path: '/quiz-result/:id',
    name: 'quiz-finish',
    component: QuizResultScreen,
    meta: { isAuth: true, title: 'quiz-finish' }
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInScreen,
    meta: { title: 'signin', layout: 'signin' }
  },
  {
    path: '/forgot-password',
    name: 'request change password',
    component: RequestResetPasswordScreen,
    meta: { title: 'change password', layout: 'signin' }
  },
  {
    path: '/reset-password',
    name: 'change password',
    component: ResetPasswordScreen,
    meta: { title: 'change password', layout: 'signin' }
  },
  {
    name: 'not-found',
    path: '/:notFound(.*)',
    component: NotFound,
    meta: { title: 'change password', layout: 'empty' }
  },
  {
    name: 'imprint',
    path: '/imprint',
    component: Imprint,
    meta: { title: 'Imprint' }
  },
  {
    path: '/configuration/style',
    redirect: () => {
      return { path: VUE_APP_API_HOST + '/style', }
    },
  },
]

export default routes
