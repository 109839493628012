<template>
  <div class="container-fluid pb-4">
    <!-- above start -->
    <div class="position-relative z-index-2 mb-2">
      <!-- title starts -->
      <div class="card card-plain mb-4">
        <div class="card-body p-0">
              <div class="d-flex flex-column mb-5">
                <h2 class="mb-0 page_title mt-sm-0 mt-2">{{ $tc('imprint') }}</h2>
              </div>
              <div v-html="content"></div>
            </div>
      </div>
    </div>
    <!-- above end -->
    <Footer />
  </div>
</template>

<script>
// import components
import Footer from "../../layouts/Footer.vue";
import {VUE_APP_API_HOST} from "@/services/http";
import ConfigService from "@/services/config_service";


export default {
  components: {Footer },
  data() {
    return {
      api_url: VUE_APP_API_HOST,
      content: ""
    };
  },
  async mounted() {
    const response = await ConfigService.getStaticPage('imprint');
    if (response.status === 200 && response.data.code === 200) {
      this.content = response.data.data;
    }
  },
  methods: {
  },
  
};
</script>

