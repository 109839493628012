<template>
  <nav
    class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="container-fluid py-1 px-0">
      <!-- breadcrumbs starts -->
      <breadcrumbs />
      <!-- breadcrumbs ends -->
      <!-- line toggler starts -->
      <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
        <a href="javascript:;" class="nav-link p-0 text-body">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </a>
      </div>
      <!-- line toggler ends -->
      <!-- languages, search box starts -->
      <div class="collapse navbar-collapse" id="navbar">
        <div class="ms-md-auto d-flex align-items-center navbar-search">
          <!-- languages -->
          <div class="box-switch-language">
            <div class="dropdown">
                <div class="dropdown-toggle"  data-bs-toggle="dropdown" aria-expanded="false" id="dropdownlanguage">
                  <span
                    class="icon icon-spain"
                    :style="`background-image: url('${'/images/flag-'+this.$i18n.locale+'.png'}');`"
                  ></span>{{languages[$i18n.locale]}}
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </div>
                <div class="dropdown-menu" role="menu" aria-labelledby="dropdownlanguage">
                  <a
                    class="dropdown-item"
                    @click="() => handleChangeLocale('en')"
                  >
                    <span class="icon icon-spain" style="background-image: url('/images/flag-en.png');">
                  </span>English</a>
                  <a
                    class="dropdown-item"
                    @click="() => handleChangeLocale('de')"
                  >
                    <span class="icon icon-spain" style="background-image: url('/images/flag-de.png');"></span>Deutsch
                  </a>
                  <a
                    class="dropdown-item"
                    @click="() => handleChangeLocale('po')"
                  >
                    <span class="icon icon-spain" style="background-image: url('/images/flag-po.png');"></span>Polish
                  </a>
                  <a
                    class="dropdown-item"
                    @click="() => handleChangeLocale('cz')"
                  >
                    <span class="icon icon-spain" style="background-image: url('/images/flag-cz.png');"></span>Czech
                  </a>
                </div>
            </div>
          </div>
          <!-- search box -->
          <div
            :class="{
              'input-group': true,
              'focused': focused
            }"
          >
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              v-model="keyword"
              type="text"
              class="form-control"
              :placeholder="$tc('type_here')"
              @focus="focused = true"
              @blur="focused = false"
              @keypress.enter="searchCourse"
            />
          </div>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="javascript:;"
              class="nav-link text-body p-0"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- languages, search box ends -->
    </div>
  </nav>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue"

export default {
  name: "Header",
  components: { Breadcrumbs },
  data() {
    return {
      keyword: "",
      focused: false,
      languages: {
        en: "English",
        de: "Deutsch",
        po: "Polish",
        cz: "Czech"
      }
    }
  },
  computed: {
    
  },
  mounted() {
  },
  methods: {
    searchCourse() {
      this.$router.push(`/my-course?keyword=${this.keyword}`)
    },
    handleChangeLocale(val) {
      this.$i18n.locale = val
      localStorage.setItem('app_locale', val);
    }
  },
};
</script>
