<template>
  <div class="container-fluid pb-4">
    <!-- title start -->
    <div class="mb-4">
      <h2 class="mb-0 page_title">
        {{ $tc('all_courses') }}
      </h2>
    </div>
    <!-- title end -->
    <!-- courses start -->
    <div class="box-grid-blog pb-4">
      <div class="row g-3 g-xxl-4 blog-inner">
        <CourseItem v-for="item in list" :key="item.id" :course="item" :addition-classes="'col-xxl-3'">
        </CourseItem>
      </div>
    </div>
    <!-- courses end -->
    <!-- footer start -->
    <Footer />
    <!-- footer end -->
  </div>
</template>

<script>
// import components
import Footer from '../../layouts/Footer.vue';
// import libs
import { createNamespacedHelpers } from 'vuex';
import CourseItem from "@/components/CourseItem";

const { mapActions, mapState } = createNamespacedHelpers('course');
const { mapActions: mapActionsCommon } = createNamespacedHelpers('common');

export default {
  components: {CourseItem, Footer },
  props: {},
  data() {
    return {
      title: this.$tc('all_courses')
    };
  },
  watch: {
    '$route.query.keyword' : {
      handler: function(keyword) {
        this.requestGetList({ keyword })
        if (keyword) {
          this.title = `Search result for “${keyword}“`
        } else {
          this.title = this.$tc('all_courses')
        }
      },
      deep: true,
    }
  },

  mounted() {
    const { keyword } = this.$route.query
    this.requestGetList({ keyword })
    this.updateBreadcrumbs([{ name: this.$tc('all_courses'), link: "/my-course"}])
  },
  computed: {
    ...mapState(['list']),
  },
  methods: {
    ...mapActions(['requestGetList']),
    ...mapActionsCommon(['updateBreadcrumbs'])
  },
};
</script>

<style lang="scss" scoped>
.title {
  min-height: 53px;
}
.box-grid-blog {
  min-height: calc(100vh - 200px);
}
</style>
