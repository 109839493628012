// import $router from '../../router'

const state = {
  notifications: [],
  listNotifications: []
}

const getters = {
  getNotifications: (state) => {
    return state.notifications
  },
}

const actions = {
  updateNotifications: async ({ commit }, data ) => {
    commit('UPDATE_NOTIFICATIONS', data)
  },
  updateList: async ({ commit }, data ) => {
    commit('UPDATE_LIST_NOTIFICATIONS', data)
  },
  appendToList: async ({ commit }, data ) => {
    commit('APPEND_LIST_NOTIFICATIONS', data)
  },
}

const mutations = {
  ['UPDATE_NOTIFICATIONS'](state, data) {
    state.notifications = [data]
  },
  ['UPDATE_LIST_NOTIFICATIONS'](state, data) {
    state.listNotifications = data
  },
  ['APPEND_LIST_NOTIFICATIONS'](state, data) {
    state.listNotifications = [data, ...state.listNotifications];
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
