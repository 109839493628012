<template>
  <div class="chart ms-auto">
    <canvas :id="id" class="chart-canvas" :height="height"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "DonutChart",
  props: {
    id: {
      type: String,
      default: "doughnut-chart",
    },
    height: {
      type: [String, Number],
      default: "160",
    },
    labels: Array,
    dataset: {
      type: Array,
      required: true,
    },
  },
  watch: {
    dataset: function () {
      if(this.dataset.length > 0)
      {
        var ctx = document.getElementById(this.id).getContext("2d");

        let chartStatus = Chart.getChart(this.id);
        console.log(chartStatus)
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }

        new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: this.labels,
            datasets: [{
              label: "Lessons",
              weight: 9,
              cutout: 50,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ['#344767', '#CB0C9F'],
              data: this.dataset,
              fill: false
            }],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              }
            },
            interaction: {
              intersect: false,
              mode: 'index',
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false
                }
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                }
              },
            },
          },
        });
      }
    },
  }
};
</script>
