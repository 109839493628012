<template>
  <div class="container-fluid pb-4">
    <div class="mb-4">
      <h2 class="mb-0 page_title">{{ $tc('do_quiz') }}</h2>
    </div>

    <div class="row">
      <div class="col-lg-4 order-lg-2">
        <div class="card -round-12 quiz-time mb-4 mb-lg-0">
          <div class="card-header p-3 p-xxl-4 pb-0 pb-xxl-0">
            <h6 class="fw700 fs-18 mb-0">{{ $tc('time_left') }}</h6>
          </div>
          <div
            v-if="dataQuiz.current_attempt"
            class="card-body -round-12 p-3 p-xxl-4 pt-3"
          >
            <div class="mb-lg-5">
              <Countdown
                :deadline="endTime()"
                mainColor="#F85032"
                :showDays="false"
                :showLabels="false"
                :flipAnimation="false"
                @timeElapsed="finishTime"
              />
            </div>
            <h6 class="fw700 fs-18 mb-3">{{ $tc('questions') }}</h6>
            <div class="quiz-time__answer">
              <ul class="row row-cols-10 g-2 list-unstyled p-0">
                <li
                  v-for="(item, index) in questions"
                  :key="item.id"
                >
                  <div :class="getClassResult(index)">{{ item.key }}</div>
                </li>
              </ul>
            </div>
            <button
              class="btn text-primary w-100 h6 border shadow-none"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#ConfirmModal"
            >{{ $tc('submit') }}</button>
          </div>
        </div>
      </div>
     <div
      class="modal fade modal-alert modals-custom"
      id="ConfirmModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="ratio ratio-1x1">
              <img src="/images/Icon_waring.png" />
            </div>
            <p class="title">
              {{ $tc('are_you_sure_want_to_submit_this_quiz') }}
            </p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button" class="btn btn-success bg-gradient-primary"
              data-bs-dismiss="modal"
              @click="submit"
            >
              {{ $tc('sure') }}
            </button>
            <button
              type="button"
              class="btn btn-cancel bg-gradient-dark"
              data-bs-dismiss="modal"
            >
              {{ $tc('cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
      <div class="col-lg-8">
        <div class="card mb-4 mb-lg-0">
          <div class="card-header p-3 p-xxl-4 pb-2 pb-xxl-2">
            <h6 class="fw700 fs-18 mb-0">
              {{ quiz.title }}
            </h6>
          </div>
          <div class="card-body p-3 p-xxl-4 pt-3">
            <ul class="quiz-list p-0 list-unstyled">
              <li
                v-for="(item, index) in questions"
                :key="item.id"
              >
                <h6 class="mb-3">
                  {{index + 1}}. {{item.content}}
                </h6>
                <div>
                  <div
                    v-for="(answer, index2) in item.answers"
                    :key="answer.id"
                    class="form-check"
                  >
                    <input
                      v-model="answers[index]"
                      class="form-check-input"
                      :type="item.answer_type == 'single_choice' ? 'radio' : 'checkbox'"
                      :value="answer.id"
                      :name="`answer-${item.id}`"
                      :id="`answer-${answer.id}`"
                    />
                    <label class="form-check-label" :for="`answer-${answer.id}`">
                      {{getAlp(index2)}}. {{answer.content}}
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { get } from 'lodash'
import moment from 'moment'
import Footer from '../../layouts/Footer.vue'
import QuizService from '../../services/quiz_service'
import { Countdown } from 'vue3-flip-countdown'

const { mapActions: mapActionsNoti } = createNamespacedHelpers('notifications')
const { mapActions: mapActionsCommon } = createNamespacedHelpers('common')

export default {
  components: {
    Footer,
    Countdown
  },
  props: {},
  data() {
    return {
      dataQuiz: {},
      answers: [],
    };
  },
  computed: {
    questions() {
      return get(this.dataQuiz, 'quiz.questions', [])
    },
    quiz() {
      return get(this.dataQuiz, 'quiz', {})
    }
  },
  watch: {
    questions(val) {
        let temp = [];
        for (let i = 0; i < val.length; i++) {
            if(val[i].answer_type == 'single_choice') {
                temp.push('');
            }
            else {
                temp.push([])
            }
        }
      this.answers = temp;
    },
    dataQuiz(val) {
      if (val.course) {
        this.updateBreadcrumbs([
          { name: this.$tc('all_courses'), link: "/my-course"},
          { name: this.$tc('course_detail'), link: `/courses/${get(this.dataQuiz, 'course.slug')}`},
          { name: this.$tc('quiz'), link: ""},
        ])
      }
    }
  },
  mounted() {
    this.getDataQuiz()
    this.updateBreadcrumbs([{ name: this.$tc('all_courses'), link: "/my-course"}])
  },
  methods: {
    ...mapActionsNoti(['updateNotifications']),
    ...mapActionsCommon(['updateBreadcrumbs']),
    async getDataQuiz() {
      const { id } = this.$route.params
      const response = await QuizService.startQuiz(id)
      const data = get(response, 'data')
      if (data.code === 200) {
        this.dataQuiz = data.data
      } else {
        this.$router.go(-1)
      }
    },
    parseDate(val) {
      return moment.utc(val, "HH:mm:ss DD.MM.YYYY")
    },
    getAlp(index) {
      const upperCaseAlp = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O",
        "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
      return upperCaseAlp[index]
    },
    async submit() {
      const { id } = this.$route.params
      const answers = this.answers.join(",")
      const response = await QuizService.finishQuiz(id, { answers })
      const data = get(response, 'data')
      if (data.code === 200) {
        this.updateNotifications({ title: this.$tc('quiz_finished'),
            message: this.$tc('quiz_finished_message') })
        this.$router.push(`/courses/${get(this.dataQuiz, 'course.slug')}`)
      }
    },
    endTime() {
      const val = new Date(this.parseDate(this.dataQuiz.current_attempt.will_finish))
      return moment(val).format("YYYY-MM-DD HH:mm:ss")
    },
    finishTime() {
      this.submit()
    },
    getClassResult(index) {
      return (Array.isArray(this.answers[index]) ? this.answers[index].length > 0 : this.answers[index]) ? '-fill' : ''
    }
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .flip-clock {
      span {
        font-size: 40px;
        line-height: 51px;
        letter-spacing: 0.02em;
        color: #F85032;
        font-weight: 700;
        text-align: center !important;
      }
      .flip-clock__piece {
        &:not(:last-child) {
          .no-animation__card {
            position: relative;
            padding-right: 10px;
            &:after {
              position: absolute;
              content: ":";
              @media (min-width: 992px) {
                right: -8px;
              }
            }
          }
        }
      }
    }
  }
</style>
