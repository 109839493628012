<template>
  <div
    :class="{
      'card p-3 discuss-item mb-4': true,
      'newComment': comment.isNewComment,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
// import components and assets

export default {
  props: {
    currentComment: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      comment: this.currentComment
    }
  },
  methods: {},
}
</script>

<style scoped>
/* general classes */
.isVoted {
  color: red;
}

.showReplies {
  opacity: 1;
  height: 100%;
}

.hideReplies {
  opacity: 0;
  height: 0;
}

.showSeeMore {
  display: inline-block;
}

.hideSeeMore {
  display: none;
}

.seeMore:hover {
  cursor: pointer;
}

.cardNoBgColor {
  background-color: rgba(0, 0, 0) !important;
}

.oldComment {
  opacity: 1;
}

.newComment {
  opacity: 0;
}

/* comment */
.discuss-item, .item-mess {
  transition: all 0.3s ease-in-out;
}

/* content */
.description .description__content {
  font-size: 14px;
}

/* reactions */
.fb:nth-child(1) {
  width: 51px;
}

.fb:nth-child(2) {
  width: 67px;
}

.fb:nth-child(3) {
  width: 62px;
}

.count_share > p {
  margin-right: 12px !important;
}

.count_share > p:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

/* replies */
.list-message {
  position: relative;
  z-index: 3;
  transition: all 0.3s ease-in-out;
}

.list-message a:hover {
  cursor: pointer;
}

/* replies */
.reply_mess, .like-mess-share {
  position: relative;
  z-index: 5;
}

/* reply input */
.commentInput {
  display: flex;
  align-items: center;
}

/* mobile responsive */
@media screen and (max-width: 767px) {
  .page_title {
    margin-top: 24px;
  }
  
  .courseDiscussContent {
    flex-direction: column-reverse !important;
  }
}
</style>
