import http from './http';

const DiscussionsService = {
  getDiscussions(courseSlug) {
    const api = `/courses/${courseSlug}/discuss`;
    return new http(true).get(api, {});
  },
  createDiscussion(courseId, content, parentId = null) {
    const api = '/discuss/create';
    return new http(true).post(api, {
      course_id: courseId,
      parent_id: parentId,
      content: content,
    })
  },
  createVote(commentId, action = 'upvote') {
    const api = '/discuss/vote';
    return new http(true).post(api, {
      comment_id: commentId,
      action: action
    })
  },
};

export default DiscussionsService;
