<template>
  <div class="chart">
    <canvas id="chart-line" class="chart-canvas" height="120"></canvas>
  </div>
</template>

<script>
// import
import Chart from "chart.js/auto";
// export default
export default {
  name: "LineChart",
  props: {
    chartData: {
      type: Array,
      required: true,
      // default: []
    },
    chartLabels: {
      type: Array,
      required: true,
      // default: []
    }
  },
  data() {
    return {
      ctx1: null,
      gradientStroke1: null
    }
  },
  methods: {
    renderChart() {
      new Chart(this.ctx1, {
        type: "line",
        data: {
          labels: this.chartLabels,
          datasets: [{
            label: "Grade",
            tension: 0.3,
            pointRadius: 2,
            pointBackgroundColor: "#CB0C9F",
            borderColor: "#CB0C9F",
            borderWidth: 2,
            backgroundColor: this.gradientStroke1,
            data: this.chartData,
            maxBarThickness: 6,
            fill: true
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            }
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7'
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7'
              }
            },
          },
        },
      });
    },
  },
  mounted() {
    this.ctx1 = document.getElementById("chart-line").getContext("2d");

    this.gradientStroke1 = this.ctx1.createLinearGradient(0, 203, 12, 159);

    this.gradientStroke1.addColorStop(1, 'rgba(203, 12, 159, 0.75)');
    this.gradientStroke1.addColorStop(0.2, 'rgba(203, 12, 159, 0.2)');
    this.gradientStroke1.addColorStop(0, 'rgba(255,255,255,0)'); //purple colors
  },
  watch: {
    chartLabels() {
      this.renderChart();
    },
  },
};
</script>
