<template>
  <div>
    <button v-show="false" type="button" ref="btnRateModal" class="btn bg-gradient-primary" data-bs-toggle="modal" data-bs-target="#RateModal">Rating</button>
    <div class="modal fade modal-form modals-custom" ref="rateModal" id="RateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- header start -->
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $tc('rate_this_course') }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <!-- header end -->
            <!-- body start -->
            <div class="modal-body">          
              <form>
                <div
                  class="start"
                  @mouseover="handleGettingRatingStars"
                >
                    <span ref="star1" class="star active"><i class="fa fa-star star1"></i></span>
                    <span ref="star2" class="star active"><i class="fa fa-star star2"></i></span>
                    <span ref="star3" class="star active"><i class="fa fa-star star3"></i></span>
                    <span ref="star4" class="star active"><i class="fa fa-star star4"></i></span>
                    <span ref="star5" class="star active"><i class="fa fa-star star5"></i></span>
                  </div>
                <div class="field">
                  <label>{{ $tc('your_feedback') }}</label>
                  <textarea
                    class="form-control"
                    placeholder="Leave your feedback here..."
                    rows="4"
                    v-model="feedback"
                  ></textarea>
                </div>
              </form>
            </div>
            <!-- body end -->
            <!-- footer start -->
            <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-success bg-gradient-primary"
                  @click="handleSubmit"
                >{{ $tc('submit') }}</button>
                <button
                  type="button"
                  class="btn btn-cancel bg-gradient-dark"
                  data-bs-dismiss="modal"
                >{{ $tc('cancel') }}</button>
            </div>
            <!-- footer end -->
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import CourseService from '../../../services/course_service'

export default {
  name: "Rating",
  props: {
    submit: {
      type: Function,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      ratingStars: 5,
      feedback: '',
    }
  },
  methods: {
    toggleModal () {
      this.$refs.btnRateModal.click()
    },
    handleSubmit() {
      this.toggleModal();
      this.submit();
      CourseService.updateRating(this.courseId, this.ratingStars, this.feedback);
      this.ratingStars = 5;
      this.feedback = '';
      this.$emit('reloadLessonAfterRating');
    },
    handleGettingRatingStars(event) {
      const currentHovering = event.target;
      if (currentHovering.classList.contains('fa-star')) {
        const hoveringStarNumber = Number(currentHovering.classList[2].at(-1));
        this.ratingStars = hoveringStarNumber;
        for (let i = 1; i <= hoveringStarNumber; i++) {
          this.$refs[`star${i}`].classList.add('active');
        }
        for (let i = hoveringStarNumber + 1; i <= 5; i++) {
          this.$refs[`star${i}`].classList.remove('active');
        }
      }
    },
  }
};
</script>

<style scoped>
.star:hover {
  cursor: pointer;
}
</style>
