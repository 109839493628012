<template>
  <div class="container-fluid">
    <!-- header start -->
    <div class="mb-4">
      <h2 class="mb-0 page_title">{{ courseTitle }}</h2>
    </div>
    <!-- header end -->
    <!-- content start -->
    <div class="course-detail-overview">
      <div class="row courseDiscussContent">
        <!-- course-discuss start -->
        <div class="col-md-8 col-xl-8 course-discuss">
          <div
            :class="{
              'card p-3 discuss-item mb-4': true,
              'newComment': comment.isNewComment,
            }"
            v-for="comment in commentsAndReplies.filter(cmt => !cmt.parent_id)"
            :key="comment.id"
          >
            <course-discuss-detail
              :currentComment="comment"
            >
              <!-- header start -->
              <div class="d-flex discuss_header">
                <div class="thumb ratio ratio-1x1">
                  <img :src="comment.sender.avatar" />
                </div>
                <div class="content">
                  <h2 class="name">{{ comment.sender.name }}</h2>
                  <p class="date">{{ comment.created_at }}</p>
                </div>
              </div>
              <!-- header end -->
              <!-- content start -->
              <div class="fs-14 fw-400 description">
                <p class="description__content">
                  {{ comment.isSeeMore ? comment.shortenContent : comment.content }}
                  <span
                    :class="{
                      'fw-600 seeMore': true,
                      'showSeeMore': comment.isSeeMore,
                      'hideSeeMore': !comment.isSeeMore
                    }"
                    @click="comment.isSeeMore = !comment.isSeeMore"
                  >{{ $tc('see_more') }}</span>
                </p>
              </div>
              <!-- content end -->
              <!-- reactions start -->
              <div class="d-flex like-mess-share">
                <!-- votes -->
                <div class="col-12 count_share_item d-flex align-items-center">
                  <a
                    :class="{
                      'fs-12 fw-400 fb': true,
                      'isVoted': comment.current_student_vote === 'upvote'
                    }"
                    href="#"
                    @click="handleCreateNewVote($event, comment, 'upvote')"
                  >
                    <i class="fa fa-thumbs-up"></i>
                    <span>{{ reactions.find(reaction => reaction.commentId === comment.id).count.upvotes }} likes</span>
                  </a>
                  <a
                    :class="{
                      'fs-12 fw-400 fb': true,
                      'isVoted': comment.current_student_vote === 'downvote'
                    }"
                    href="#"
                    @click="handleCreateNewVote($event, comment, 'downvote')"
                  >
                    <i class="fa fa-thumbs-down"></i>
                    <span>{{ reactions.find(reaction => reaction.commentId === comment.id).count.downvotes }} dislikes</span>
                  </a>
                  <a
                    class="fs-12 fw-400 comment"
                    href="#"
                    @click.prevent="comment.showCorrespondingReplies = !comment.showCorrespondingReplies"
                  >
                    <i class="fa fa-comment"></i>
                    <span>{{ reactions.find(reaction => reaction.commentId === comment.id).count.replies }} replies</span>
                  </a>
                  <!-- fa fa-share -->
                </div>
              </div>
              <!-- reactions end -->
              <!-- replies start -->
              <div
                class="list-message"
                :class="{
                  'list-message': true,
                  'showReplies': comment.showCorrespondingReplies,
                  'hideReplies': !comment.showCorrespondingReplies,
                }"
              >
                <div
                  class="d-flex item-mess"
                  :class="{
                    'd-flex item-mess': true,
                    'newComment': reply.isNewComment,
                  }"
                  v-for="reply in commentsAndReplies.filter(rep => rep.parent_id == comment.id)"
                  :key="reply.id"
                >
                  <div class="thumb ratio ratio-1x1">
                    <img :src="reply.sender.avatar" />
                  </div>
                  <div class="content">
                    <h2 class="fs-14 fw-700 name">{{ reply.sender.name }}</h2>
                    <p class="fs-14 fw-400 mess">
                      {{ reply.isSeeMore ? checkContentEmoji(reply.shortenContent) : checkContentEmoji(reply.content) }}
                      <span
                        :class="{
                          'fw-600 seeMore': true,
                          'showSeeMore': reply.isSeeMore,
                          'hideSeeMore': !reply.isSeeMore
                        }"
                        @click="reply.isSeeMore = !reply.isSeeMore"
                      >{{ $tc('see_more') }}</span>
                    </p>
                    <div class="count_share_item">
                      <a
                        :class="{
                          'fs-12 fw-400 fb': true,
                          isVoted: reply.current_student_vote === 'upvote'
                        }"
                        @click="handleCreateNewVote($event, reply, 'upvote')"
                      >
                        <i class="fa fa-thumbs-up"></i>
                        <span>{{ reply.count.upvotes }}</span>
                      </a>
                      <a
                        :class="{
                          'fs-12 fw-400 fb': true,
                          isVoted: reply.current_student_vote === 'downvote'
                        }"
                        @click="handleCreateNewVote($event, reply, 'downvote')"
                      >
                        <i class="fa fa-thumbs-down"></i>
                        <span>{{ reply.count.downvotes }}</span>
                      </a>
                      <a class="fs-12 fw-400 share">
                        <i class="fa fa-comment"></i>
                        <span>{{ reply.count.replies }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- replies end -->
              <!-- reply input start -->
              <div class="reply_mess">
                <div
                  class="commentInput"
                >
                  <div class="thumb ratio ratio-1x1">
                    <img :src="me.profilePicture" />
                  </div>
                  <div class="text_mess">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$tc('write_your_reply')"
                      v-model="comment.writeYourComment"
                      @keydown="handleCreateNewReply($event, comment, comment.id)"
                      @input="checkInputingEmoji($event)"
                    />
                  </div>
                </div>
              </div>
              <!-- reply input end -->
            </course-discuss-detail>
          </div>
        </div>
        <!-- course-discuss end -->
        <!-- sidebar start -->
        <div class="col-md-4 col-xl-4 course-sidebar widget__sidebar">
          <div class="card cardNoBgColor find_topic mb-4">
            <div class="mh-600 topic__inner">
              <!-- <h2 class="widget__title">Find your topic</h2> -->
              <div class="form">
                <!-- <div class="d-flex align-items-center lession find_item">
                  <label class="fs-14 fw-400">Lesson:</label>
                  <div class="select_field">
                    <select class="fs-14 fw-400 form-select">
                      <option>Lesson 5 - Basic letter drawing</option>
                      <option>Lesson 5 - Basic letter drawing</option>
                      <option>Lesson 5 - Basic letter drawing</option>
                      <option>Lesson 5 - Basic letter drawing</option>
                      <option>Lesson 5 - Basic letter drawing</option>
                    </select>
                  </div>
                </div> -->
                <!-- <div class="d-flex align-items-center find_item">
                  <label class="fs-14 fw-400">Sort by:</label>
                  <div class="d-flex sort_by select_field">
                    <select class="fs-14 fw-400 form-select">
                      <option>Newest</option>
                      <option>Newest</option>
                      <option>Newest</option>
                      <option>Newest</option>
                      <option>Newest</option>
                    </select>
                    <select class="fs-14 fw-400 form-select">
                      <option>Most comment</option>
                      <option>Most comment</option>
                      <option>Most comment</option>
                      <option>Most comment</option>
                      <option>Most comment</option>
                    </select>
                  </div>
                </div> -->
                <button
                  class="btn w-100 btn-primary-2"
                  data-bs-toggle="modal"
                  data-bs-target="#create_topic"
                >
                  {{ $tc('write_a_comment') }}
                </button>
                <div
                  class="modal fade modal-form modals-custom modals-topic"
                  id="create_topic"
                  tabindex="-1"
                  aria-labelledby="create_topic"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="create_topic">
                          {{ $tc('write_a_comment') }}
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                        </button>
                      </div>
                      <div class="modal-body">
                        <form>
                          <div class="field">
                            <textarea
                              class="form-control"
                              :placeholder="$tc('enter_your_comment_here')"
                              rows="4"
                              v-model="newDiscussion"
                              @input="checkInputingEmoji($event)"
                            ></textarea>
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer justify-content-between">
                        <button
                          type="button"
                          class="btn btn-success bg-gradient-primary"
                          @click="handleCreateNewDiscussion"
                          data-bs-dismiss="modal"
                        >{{ $tc('create') }}</button>
                        <button
                          type="button"
                          class="btn btn-cancel bg-gradient-dark"
                          data-bs-dismiss="modal"
                        >{{ $tc('cancel') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- sidebar end -->
      </div>
    </div>
    <!-- content end -->
    <!-- footer start -->
    <Footer />
    <!-- footer end -->
  </div>
</template>


<script>
// import components and assets
import Footer from "../../layouts/Footer.vue";
import CourseDiscussDetail from './components/CourseDiscussDetail.vue';
import emojisCompact from '../../assets/emoji/emoji.json';
import emojiMixin from './mixins/emoji.js';
// import services
import DiscussionsService from '../../services/discussions_service';
import UserService from '../../services/user_service';
// import libs
import Pusher from 'pusher-js';

export default {
  components: { Footer, CourseDiscussDetail },
  mixins: [emojiMixin],
  data() {
    return {
      courseSlug: '',
      courseId: null,
      courseTitle: null,
      me: { profilePicture: '' },
      
      pusher: null,
      channelCourse: null,
      comments: [],
      commentsAndReplies: [],
      reactions: [],

      notFirstSubscribe: 0,

      newDiscussion: '',

      emojisCollection: emojisCompact,
    };
  },
  mounted() {
    this.courseSlug = this.$route.params.courseSlug;
    this.courseId = this.$route.query.course_id;
    const pusherAppKey = process.env.VUE_APP_PUSHER_APP_KEY;
    this.pusher = new Pusher(pusherAppKey, { cluster: 'eu' });
    this.getDiscussionsDetail(this.courseSlug);

    // const tMounted = performance.now();
    // console.log('tMounted');
    // console.log(tMounted);
  },
  updated() {
    // const tUpdated = performance.now();
    // console.log('tUpdated');
    // console.log(tUpdated);
  },
  unmouted() {
    this.reset();
  },
  methods: {
    async getDiscussionsDetail(courseSlug) {
      const response = await DiscussionsService.getDiscussions(courseSlug);
      const data = response.data;
      if (data.code === 200) {
        // re-assign values
        this.comments = data.data.comments;
        this.comments = this.comments.map(comment => {
          comment.content = this.checkContentEmoji(comment.content);
          comment.writeYourComment = '';
          comment.isSeeMore = this.checkIsSeeMore(comment.content);
          comment.shortenContent = comment.isSeeMore ? this.shortenContent(comment.content) : '';
          comment.showCorrespondingReplies = false;
          comment.isNewComment = true;
          return comment;
        });

        const initReplies = this.comments
          .filter(comment => comment.replies.length)
          .map(comment => comment.replies)
          .flat(Infinity)
          .reverse();
        const replies = initReplies.map(rep => {
          rep.writeYourComment = '';
          rep.isSeeMore = this.checkIsSeeMore(rep.content);
          rep.shortenContent = rep.isSeeMore ? this.shortenContent(rep.content) : '';
          rep.showCorrespondingReplies = false;
          return rep;
        })
        this.commentsAndReplies = [
          ...this.comments,
          ...replies
        ];
        // change isNewComment to false, in order to make the comments fade in
        setTimeout(() => {
          this.commentsAndReplies = this.commentsAndReplies.map(comment => {
            comment.isNewComment = false;
            return comment;
          })
        }, 500)

        this.reactions = this.commentsAndReplies.map(comment => ({
          commentId: comment.id,
          parentId: comment.parent_id,
          count: comment.count,
          owner: comment.sender,
        }));

        this.courseTitle = this.courseSlug.split('_').join(' ');
        // get me
        this.getMe();
        // subscribe channel
        this.subscribe();
      }
    },
    async getMe() {
      const response = await UserService.getInfo();
      if (response.status === 200 && response.data.code === 200) {
        this.me = response.data.data;
      }
    },
    reset() {
      this.unsubscribe();
      this.courseSlug = '';
      this.courseId = null;
      this.me = { profilePicture: '' };
      
      this.pusher = null;
      this.channelCourse = null;
      this.channelComments = null;
      this.checkFirstSubscribe = 0;
      this.comments = [];
      this.reactions = [];

      this.showingWriteYourComment = null;
      this.writeYourComment = '';
      this.newDiscussion = '';
    },
    unsubscribe() {
      this.pusher.unsubscribe(`course-${this.courseId}-discussion`);
    },
    subscribe() {
      this.channelCourse = this.pusher.subscribe(`course-${this.courseId}-discussion`);
      // commented
      this.channelCourse.bind('commented', data => {
        data.data.content = this.checkContentEmoji(data.data.content);
        data.data.writeYourComment = '';
        data.data.isSeeMore = this.checkIsSeeMore(data.data.content);
        data.data.shortenContent = data.data.isSeeMore ? this.shortenContent(data.data.content) : '';
        data.data.isNewComment = true;

        this.comments.unshift(data.data);
        this.commentsAndReplies.unshift(data.data);
        setTimeout(() => { // change isNewComment from true to false -> the comment fadein
          this.commentsAndReplies[0].isNewComment = false;
        }, 500)

        this.reactions.unshift({
          commentId: data.data.id,
          count: data.data.count,
        });
        if (this.notFirstSubscribe) {
          this.commentsSubscribing(this.commentsAndReplies[0]);
        }
        this.showingWriteYourComment = data.data.id;
      })
      // commented-{id} - voted-{id}
      if (!this.notFirstSubscribe) {
        this.notFirstSubscribe++;
        const commentsAndRepliesLength = this.commentsAndReplies.length;
        for (let i = 0; i < commentsAndRepliesLength; i++) {
          this.commentsSubscribing(this.commentsAndReplies[i]);
        }
      }
    },
    commentsSubscribing(comment) {
      const commentId = comment.id;
      // commented-{id}
      this.channelCourse.bind(`commented-${commentId}`, data => {
        data.data.content = this.checkContentEmoji(data.data.content);
        data.data.parent_id = Number(data.data.parent_id);
        data.data.isSeeMore = this.checkIsSeeMore(data.data.content);
        data.data.shortenContent = data.data.isSeeMore ? this.shortenContent(data.data.content) : '';
        data.data.isNewComment = true;

        const commentsIndex = this.comments.findIndex(cmt => cmt.id === data.data.parent_id);
        this.comments[commentsIndex].replies.unshift(data.data);

        this.commentsAndReplies.unshift(data.data)
        setTimeout(() => { // change isNewComment from true to false -> the reply fade in
          this.commentsAndReplies[0].isNewComment = false;
        }, 500)

        this.reactions.unshift({
          commentId: data.data.id,
          count: data.data.count
        });

        this.commentsSubscribing(this.commentsAndReplies[0]);
      })
      // voted-{id}
      this.channelCourse.bind(`voted-${commentId}`, data => {
        const commentId = Number(data.event.split('-')[1]);

        const commentsIndex = this.comments.findIndex(cmt => cmt.id === commentId);
        if (commentsIndex >= 0) {
          this.comments[commentsIndex].count.upvotes = data.data.upvotes;
          this.comments[commentsIndex].count.downvotes = data.data.downvotes;
        }

        const commentsAndRepliesIndex = this.commentsAndReplies.findIndex(cmt => cmt.id === commentId); 
        this.commentsAndReplies[commentsAndRepliesIndex].count.upvotes = data.data.upvotes;
        this.commentsAndReplies[commentsAndRepliesIndex].count.downvotes = data.data.downvotes;
        
        const reactionsIndex = this.reactions.findIndex(react => react.commentId === commentId);
        this.reactions[reactionsIndex].upvotes = data.data.upvotes;
        this.reactions[reactionsIndex].downvotes = data.data.downvotes;
      })
    },
    handleCreateNewDiscussion() {
      DiscussionsService.createDiscussion(this.courseId, this.newDiscussion);
      this.newDiscussion = '';
    },
    handleCreateNewVote(event, comment, action) {
      event.preventDefault();
      const correspondingComment = this.commentsAndReplies.find(cmt => cmt.id === comment.id);
      const correspondingIndex = this.commentsAndReplies.findIndex(cmt => cmt.id === comment.id);

      const finalAction = action == correspondingComment.current_student_vote ? "unvote" : action;

      this.commentsAndReplies[correspondingIndex].current_student_vote = finalAction === 'unvote' ? false : finalAction; // change current_student_vote -> corresponding color

      DiscussionsService.createVote(comment.id, finalAction);
    },
    handleCreateNewReply(event, comment, commentId) {
      if (event.key === 'Enter' && comment.writeYourComment) {
        DiscussionsService.createDiscussion(this.courseId, comment.writeYourComment, commentId);
        event.target.blur();

        const commentsIndex = this.comments.findIndex(cmt => cmt.id === commentId);
        this.comments[commentsIndex].writeYourComment = '';
        setTimeout(() => {
          this.comments[commentsIndex].showCorrespondingReplies = true;
        }, 1000)

        const commentsAndRepliesIndex = this.commentsAndReplies.findIndex(cmt => cmt.id === commentId);
        this.commentsAndReplies[commentsAndRepliesIndex].writeYourComment = '';
        this.commentsAndReplies[commentsAndRepliesIndex].count.replies++;
        setTimeout(() => {
          this.commentsAndReplies[commentsAndRepliesIndex].showCorrespondingReplies = true;
        }, 1000)
      }
    },
    checkIsSeeMore(comment) {
      const commentArray = comment.split(' ');
      const windowWidth = window.innerWidth;

      if ( windowWidth >= 1440 && commentArray.length > 90
        || windowWidth >= 768 && windowWidth < 1440 && commentArray.length > 60
        || windowWidth < 768 && commentArray.length > 50
      ) {
        return true;
      }
      else return false;
    },
    shortenContent(comment) {
      const commentArray = comment.split(' ');
      const windowWidth = window.innerWidth;

      // desktop: average 30 words/line, shorten when length exceeds 90 words
      if (windowWidth >= 1440) {
        if (commentArray.length > 90) {
          return commentArray.slice(0, 90).join(' ').concat('...');
        }
      }
      // tablet: average 13 (vertical) and 18 (horizontal) words/line, shorten when length exceeds 60 words
      else if (windowWidth >= 768 && windowWidth < 1440) {
        if (commentArray.length > 60) {
          return commentArray.slice(0, 60).join(' ').concat('...');
        }
      }
      // phone: average 13 words/line, shorten when length exceeds 50 words
      else if (windowWidth < 768) {
        if (commentArray.length > 50) {
          return commentArray.slice(0, 50).join(' ').concat('...');
        }
      }
      return commentArray.join(' ')
    },
  },
};
</script>


<style scoped>
/* general classes */
.isVoted {
  color: red;
}

.showReplies {
  opacity: 1;
  height: 100%;
}

.hideReplies {
  opacity: 0;
  height: 0;
}

.showSeeMore {
  display: inline-block;
}

.hideSeeMore {
  display: none;
}

.seeMore:hover {
  cursor: pointer;
}

.cardNoBgColor {
  background-color: rgba(0, 0, 0) !important;
}

.oldComment {
  opacity: 1;
}

.newComment {
  opacity: 0;
}

/* comment */
.discuss-item, .item-mess {
  transition: all 0.3s ease-in-out;
}

/* content */
.description .description__content {
  font-size: 14px;
}

/* reactions */
.fb:nth-child(1) {
  width: 51px;
}

.fb:nth-child(2) {
  width: 67px;
}

.fb:nth-child(3) {
  width: 62px;
}

.count_share > p {
  margin-right: 12px !important;
}

.count_share > p:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

/* replies */
.list-message {
  position: relative;
  z-index: 3;
  transition: all 0.3s ease-in-out;
}

.list-message a:hover {
  cursor: pointer;
}

/* replies */
.reply_mess, .like-mess-share {
  position: relative;
  z-index: 5;
}

/* reply input */
.commentInput {
  display: flex;
  align-items: center;
}

/* mobile responsive */
@media screen and (max-width: 767px) {
  .page_title {
    margin-top: 24px;
  }
  
  .courseDiscussContent {
    flex-direction: column-reverse !important;
  }
}
</style>
