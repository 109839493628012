<template>
  <section>
    <div class="page-header-dark min-vh-100">
      <div class="container pb-lg-0">
        <div class="row">
          <div
            class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
          >
            <div class="card card-plain">
              <img src="../../assets/images/logo.png" class="w-75 px-1 my-5 d-lg-none" />
              <div class="card-header-dark p-3 mt-5 p-xxl-4 pb-2 pb-xxl-2 text-start">
                <h4 class="font-weight-bolder">{{ $tc('change_password') }}</h4>
                <p class="mb-0">{{ $tc('enter_your_email_new_password_and_confirmation') }}</p>
              </div>
              <div class="card-body p-3 p-xxl-4">
                <form role="form">
                  <div class="mb-3">
                    <base-input
                      ref="email"
                      v-model="form.email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      class-name="form-control form-control-lg"
                      errorRequired="Please enter an email"
                      required
                    ></base-input>
                  </div> 
                  <div class="mb-3">
                    <base-input
                      ref="password"
                      v-model="form.password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      class-name="form-control form-control-lg"
                      errorRequired="Please enter an password"
                      required
                    ></base-input>
                  </div>
                  <div class="mb-3">
                    <base-input
                      ref="password_confirmation"
                      v-model="form.password_confirmation"
                      type="password"
                      placeholder="Password Confirmation"
                      aria-label="Password"
                      class-name="form-control form-control-lg"
                      errorRequired="Please enter again an password"
                      required
                    ></base-input>
                  </div>
                  <div class="invalid-feedback text-center">
                    <span>
                      {{ errorApi }}
                    </span>
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-lg bg-gradient-primary btn-lg h6 w-100 mt-4 mb-0"
                      :disabled="isSubmit"
                      @click="submit"
                    >
                      {{ $tc('submit') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            class="col-6 d-lg-flex d-none h-100 my-auto p-3 position-absolute top-0 end-0 justify-content-center flex-column"
          >
            <div
              class="position-relative h-100 px-xxl-6 pb-xxl-5 px-5 pb-4 border-radius-lg d-flex flex-column justify-content-end"
              style="
                background: url(images/login-banner.jpg) center no-repeat;
              "
            >
              <img
                src="/images/login-bg-line.svg"
                alt="pattern-lines"
                class="position-absolute opacity-2 top-0 start-0"
              />

              <h4 class="fs-24 fs-xxl-32 text-white font-weight-bolder mb-2">
                "{{ $tc('attention_is_the_new_currency') }}"
              </h4>
              <p class="mb-0 text-white">
                {{ $tc('the_more_effortless_the_writing_looks') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { get } from 'lodash'
import BaseInput from "@/components/BaseInput.vue"
import AuthService from '@/services/auth_service'

const { mapActions } = createNamespacedHelpers('notifications')

export default {
  components: {
    BaseInput
  },
  props: {
  },
  data() {
    const form = {
      password: "",
      email: "",
      password_confirmation: ""
    }

    return { form, errorApi: "", isSubmit: false }
  },
  computed: {
  },
  mounted() {
    const { token } = this.$route.query
    this.form.token = token;
    this.$el.addEventListener("keypress", function(e) {
      if(e.keyCode === 13) {
        this.submit();
      }
    }.bind(this));
  },
  methods: {
    ...mapActions(['updateNotifications']),
    async submit() {
      const nameValid = this.$refs.email.validate()
      const passwordValid = this.$refs.password.validate()
      const passwordConfirmValid = this.$refs.password_confirmation.validate()
      if (nameValid && passwordValid && passwordConfirmValid) {
        this.isSubmit = true
        const response = await AuthService.resetPwd(this.form)
        const data = get(response, 'data', {})
        if (data.code === 200) {
          this.updateNotifications([{ title: data.message }])
          this.$router.push('/signin')
        } else {
          this.errorApi = data.error
        }
        this.isSubmit = false
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}
h4 {
   color: rgb(0, 0, 0);
  //color: rgb(255, 255, 255);
}
</style>
