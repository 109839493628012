import http from './http'

const CourseService = {
  getList(params) {
    const api = '/courses'
    return new http(true).get(api, { params })
  },
  getDetail(courseSlug) {
    const api = `/courses/${courseSlug}`
    return new http(true).get(api, {})
  },
  updateRating(id, star, feedback) {
    const api = `/courses/review`
    return new http(true).post(api, { 
      course_id: id,
      rate: star,
      feedback: feedback
     })
  },
}

export default CourseService
