
const state = {
  breadcrumbs: []
}

const actions = {
  updateBreadcrumbs: async ({ commit }, data ) => {
    commit('UPDATE_BREADCRUMBS', data)
  }
}

const mutations = {
  ['UPDATE_BREADCRUMBS'](state, data) {
    state.breadcrumbs = data
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
