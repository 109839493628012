<template>
  <div class="container-fluid pb-4">
    <!-- above start -->
    <div class="position-relative z-index-2 mb-2">
      <!-- title starts -->
      <div class="card card-plain mb-4">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-column h-100">
                <h2 class="mb-0 page_title mt-sm-0 mt-2">{{ $tc('dashboard') }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- title ends -->
      <!-- badge starts -->
      <div class="box-badges-dashboard">
        <div class="row mt-1 mt-xxl-2 g-3 g-xxl-4">
          <!-- hello me.name -->
          <div class="col-lg-4 col-sm-12 col-12 mt-0 mb-3">
            <div class="blog-item-abs">
              <div class="blog-inner">
                <div class="overlap"></div>
                <div class="item-inner">
                  <h5 class="fs-18 fw700 txt-black title">{{ $tc('hello') }}, {{ me.name }}!</h5>
                  <p class="txt-black fs-14 fw400 content text-line-clamp clamp-2">
                    Wealth creation is an evolutionarily recent positive-sum game.
                    It is all about who takes the opportunity first.
                  </p>
                  <img
                    class="badge-banner"
                    :src="api_url + '/get-image/dashboard_welcome_image'"
                    alt="LMS Welcome Image"
                  />
                  <div class="readmore">
                    <router-link class="txt-white fs-12 fw700" to="/notification"
                    >{{ $tc('read_more') }} &ensp;
                      <i class="fa fa-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- badge -->
          <div class="col-lg-2 col-sm-3 col-6 mt-0 mb-3"
               v-for="(medal, index) in medals"
                :key="'medal-'+index">
            <div class="card box-icon-text">
              <div class="card-header p-3 p-xxl-4 pb-xxl-3">
                <div class="bximg ratio ratio-1x1 -contain">
                  <img :src="medal.image" />
                </div>
              </div>
              <div class="card-body p-3 p-xxl-4 pt-0 pt-xxl-0 text-center">
                <h6 class="text-center mb-2 mt-lg-2 fs-18 fw700 title">{{ medal.name }}</h6>
                <span
                  class="fs-12 fw-400 color-primary-2 sub_title"
                >{{ medal.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- badge ends -->
      <!-- dashboard starts -->
      <div class="box-top-dashboard">
        <div class="row g-3 g-xxl-4">
          <!-- count -->
          <div class="col-lg-6 count-dashboard">
            <div class="row g-3 g-xxl-4">
              <!-- all courses -->
              <div class="col-sm-6">
                <div class="card box_count_icon">
                  <div class="card-body p-3 p-xxl-4">
                    <div class="row g-1">
                      <div class="col-8">
                        <div class="numbers">
                          <p
                            class="text-sm mb-0 text-capitalize fs-14 fw-700"
                          >{{ $tc('all_courses') }}</p>
                          <h5 class="count fw700 fs-18">{{ allCoursesNumber }}</h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div
                          class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                        >
                          <span class="icon-img"
                            ><img src="images/BookOpen.png" width="" height=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- pass rate -->
              <div class="col-sm-6">
                <div class="card box_count_icon">
                  <div class="card-body p-3 p-xxl-4">
                    <div class="row g-1">
                      <div class="col-8">
                        <div class="numbers">
                          <p
                            class="text-sm mb-0 text-capitalize fs-14 fw-700"
                          >{{ $tc('pass_rate') }}</p>
                          <h5 class="count fw700 fs-18">{{ passRate }}</h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div
                          class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                        >
                          <span class="icon-img"
                            ><img
                              src="images/GraduationCap.png"
                              width=""
                              height=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- on-going courses -->
              <div class="col-sm-6">
                <div class="card box_count_icon">
                  <div class="card-body p-3 p-xxl-4">
                    <div class="row g-1">
                      <div class="col-8">
                        <div class="numbers">
                          <p
                            class="text-sm mb-0 text-capitalize fs-14 fw-700"
                          >{{ $tc('on_going_courses') }}</p>
                          <h5 class="count fw700 fs-18">
                            {{ onGoingCoursesNumber }}
                          </h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div
                          class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                        >
                          <span class="icon-img"
                            ><img
                              src="images/BookmarksSimple.png"
                              width=""
                              height=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- medals -->
              <div class="col-sm-6">
                <div class="card box_count_icon">
                  <div class="card-body p-3 p-xxl-4">
                    <div class="row g-1">
                      <div class="col-8">
                        <div class="numbers">
                          <p
                            class="text-sm mb-0 text-capitalize fs-14 fw-700"
                          >
                            {{ $tc('medals') }}
                          </p>
                          <h5 class="count fw700 fs-18">{{ medalsNumber }}</h5>
                        </div>
                      </div>
                      <div class="col-4 text-end">
                        <div
                          class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                        >
                          <span class="icon-img"
                            ><img src="images/Medal.png" width="" height=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- chart, last login -->
          <div class="col-lg-6 char-dashboard h-100">
            <div class="row g-3 g-xxl-4">
              <!-- chart -->
              <div class="col-sm-8 h-100">
                <div class="card overflow-hidden h-100">
                  <div class="card-header p-3 p-xxl-4 pb-0 pb-xxl-0">
                    <div class="d-flex align-items-center">
                      <div
                        class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                      >
                        <span class="icon-img"
                          ><img src="images/circle.png" width="" height=""
                        /></span>
                      </div>
                      <div class="ms-3">
                        <p class="mb-0 fw700 fs-14 color-primary-2">
                          {{ $tc('average_points') }}
                        </p>
                        <h5 class="fw700 fs-18 color-primary-1 mb-0">{{ averagePoints }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3 p-xxl-4 pb-0 pb-xxl-0">
                    <line-chart
                      :chartData="chartData"
                      :chartLabels="chartLabels"
                    ></line-chart>
                  </div>
                </div>
              </div>
              <!-- last login -->
              <div class="col-sm-4">
                <div class="card h-100 box2_count_icon">
                  <div class="card-body d-flex flex-sm-column p-3 p-xxl-4">
                    <div
                      class="icon icon-shape bg-primary-cs shadow text-center box-icon-img"
                    >
                      <span class="icon-img"
                        ><img
                          src="images/ClockAfternoon.png"
                          width=""
                          height=""
                      /></span>
                    </div>
                    <div class="card-meta mt-auto ps-3 ps-sm-0">
                      <p class="mb-0 me-3 sub_title fs-14 fw-700 ms-auto color-primary-2">{{ $tc('last_login') }}</p>
                      <h5 class="mb-0 count_title">
                        <span class="count fw-700 fs-18">{{ lastLogIn }}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- dashboard ends -->
    </div>
    <!-- above end -->
    <!-- ongoing course - continue studying start -->
    <div class="dashboard-list-blog pt-4 pb-4">
      <div class="row g-3 g-xxl-4">
        <!-- Ongoing Courses Starts -->
        <div class="col-xxl-8 box-grid-blog">
          <h2 class="fs-24 fw700 title-blog mb-3 uppercase">{{ $tc('on_going_courses') }}</h2>
          <div class="row g-3 g-xxl-4 blog-inner">
            <CourseItem v-for="(course, index) in ongoingCourses" :key="'course-'+index" :course="course">
            </CourseItem>
          </div>
        </div>
        <!-- Ongoing Courses Ends -->
        <!-- Continue Study Starts -->
        <div class="col-xxl-4 box-grid-video">
          <h2 class="fs-24 fw700 title-blog mb-3 uppercase">{{ $tc('continue_studying') }}</h2>
          <div class="row g-3 g-xxl-4 grid-inner">
            <div
              class="col-xxl-6 col-md-3 col-sm-6 col-12 video-item"
              v-for="(lesson, index) in ongoingLessons"
              :key="'lesson-'+index"
            >
              <div
                class="video-inner position-relative"
                :style="`background: url(${lesson.image}) center center / cover no-repeat;`"
              >
                <div class="content">
                  <!-- video link -->
                  <div class="video-link d-flex justify-content-center align" @click="$router.push(`/courses/${lesson.course_slug}?lesson_id=${lesson.id}`)">
                    <span class="icon-video">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >    
                        <path
                          d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"
                        />
                      </svg>
                    </span>
                  </div>
                  <!-- video link -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Continue Study Ends -->
      </div>
    </div>
    <!-- ongoing course - continue studying end -->
    <Footer />
  </div>
</template>

<script>
// import components
import Footer from "../../layouts/Footer.vue";
import LineChart from "../../components/LineChart.vue"
import {VUE_APP_API_HOST} from "@/services/http";

// import libs
import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapActionsCommon } = createNamespacedHelpers('common');
// import services
import DashboardService from "@/services/dashboard_service";
import UserService from '@/services/user_service';
import CourseItem from "@/components/CourseItem";

export default {
  components: {CourseItem, Footer, LineChart },
  data() {
    return {
      me: { name: '' },

      ongoingCourses: [],
      ongoingLessons: [],
      medals: [],
      allCoursesNumber: null,
      onGoingCoursesNumber: null,
      passRate: null,
      medalsNumber: null,

      chartData: [],
      chartLabels: [],

      averagePoints: null,
      lastLogIn: null,
      api_url: VUE_APP_API_HOST
    };
  },
  async mounted() {
    this.updateBreadcrumbs([{ name: 'Dashboard', link: "/"}]);

    const response = await DashboardService.getDashboardInfo();
    const data = response.data.data;

    const response2 = await UserService.getInfo();
    const data2 = response2.data.data;

    if (response.data.code === 200 && response2.data.code === 200) {
      this.me = data2;

      this.ongoingCourses = data.ongoing_courses;
      this.ongoingLessons = data.ongoing_lessons;
      this.medals = data.certificates;

      this.allCoursesNumber = data.count.all_courses;
      this.onGoingCoursesNumber = data.count.ongoing_courses;
      this.medalsNumber = data.count.medal;
      this.passRate = data.count.pass;

      this.averagePoints = data.chart.average;
      this.chartData = data.chart.data;
      this.chartLabels = data.chart.labels;
      this.lastLogIn = data.last_logged_in;
    }
  },
  methods: {
    ...mapActionsCommon(['updateBreadcrumbs'])
  },
  
};
</script>

<style>
.blog-inner .blog-item:hover {
  cursor: pointer;
}

.video-inner .content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-badges-dashboard > div {
  height: 100%;
}

.box-badges-dashboard .card {
  height: 100%;
}

.box-badges-dashboard .card-header {
  height: 65%;
  display: flex;
  align-items: center;
}

.box-badges-dashboard .card-body {
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-inner .item-inner > h5 {
  height: 12%;
}

.blog-inner .item-inner > p {
  height: 28%;
  margin-bottom: 12px !important;
}

.blog-inner .item-inner > img {
  width: 100%;
  max-height: calc(60% - 24px);
  object-fit: cover;
}
</style>
