<template>
  <div>
    <button
      v-show="false"
      type="button"
      class="btn bg-gradient-primary mx-1"
      data-bs-toggle="modal"
      data-bs-target="#ConfirmModalDoQuiz"
      ref="btnConfirmModalDoQuiz"
    >{{ $tc('confirm') }}</button>
    <div
      class="modal fade modal-alert modals-custom"
      id="ConfirmModalDoQuiz"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="ratio ratio-1x1">
              <img src="/images/Icon_waring.png" />
            </div>
            <p class="title">
              {{ $tc('you_havent_done_any_quiz_do_lesson_quizzes_now') }}
            </p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button" class="btn btn-success bg-gradient-primary"
              data-bs-dismiss="modal"
              @click="doQuiz"
            >{{ $tc('sure') }}</button>
            <button
              type="button"
              class="btn btn-cancel bg-gradient-dark"
              data-bs-dismiss="modal"
              @click="cancelDoQuiz"
            >{{ $tc('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Rating",
  props: {
    doQuiz: {
      type: Function,
      required: true
    },
    currentCourse: {
      type: Object,
      required: true
    },
    currentLesson: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      totalLessonsNumber: null,
      currentLessonId: null,
    }
  },
  methods: {
    toggleModal () {
      this.$refs.btnConfirmModalDoQuiz.click()
    },
    cancelDoQuiz() {
      if (this.currentLessonId !== this.currentCourse.lessons.at(-1).id) {
        const currentLessonIndex = this.currentCourse.lessons.findIndex(course => course.id === this.currentLessonId);
        const nextLessonIndex = currentLessonIndex + 1;
        const nextLesson = this.currentCourse.lessons[nextLessonIndex];
        this.$emit('navigateToNextLessonFromDoQuiz', nextLesson.id);
      }
    },
  },
  watch: {
    currentCourse() {
      this.totalLessonsNumber = this.currentCourse.lessons.length;
    },
    currentLesson() {
      this.currentLessonId = this.currentLesson.id;
    },
  },
};
</script>
