import http from './http'

const ConfigService = {
  getBrand() {
    const api = '/configuration/brand'
    return new http(false).get(api, {})
  },

  getStyle() {
    const api = '/configuration/style'
    return new http(false).get(api, {})
  },

  getStaticPage(type) {
    const api = '/configuration/static-page/' + type
    return new http(false).get(api, {})
  },
}

export default ConfigService
