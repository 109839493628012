<template>
  <section>
    <div class="page-header-dark min-vh-100">
      <div class="container">
        <div class="row">
          <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
            <div class="card card-plain">
              <img src="../../assets/images/logo.png" class="w-75 px-1 my-5 d-lg-none" />
              <div class="card-header-dark p-3 mt-5 p-xxl-4 pb-2 pb-xxl-2 text-start">
                <h4 class="font-weight-bolder uppercase">{{ $tc('change_password') }}</h4>
                <p
                  class="mb-0"
                  v-show="isShownDescription"
                >{{ $tc('enter_your_email') }}</p>
              </div>
              <div v-if="!apiSuccess" class="card-body p-3 p-xxl-4">
                <div role="form">
                  <div class="mb-3">
                    <base-input
                      ref="email"
                      v-model="form.email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      class-name="form-control form-control-lg"
                      errorRequired="Please enter an email"
                      required
                    ></base-input>
                  </div>

                  <div class="invalid-feedback text-center">
                    <span>{{ errorApi }}</span>
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-lg bg-gradient-primary btn-lg h6 w-100 mt-4 mb-0"
                      :disabled="isSubmit"
                      @click="submit"
                    >{{ $tc('submit') }}</button>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 ps-3 ps-xxl-4" v-else>
                <p class="mb-0">{{ apiSuccess }}</p>
              </div>
            </div>
          </div>
          <div
            class="col-6 d-lg-flex d-none h-100 my-auto p-3 position-absolute top-0 end-0 justify-content-center flex-column"
          >
            <div
              class="position-relative h-100 px-xxl-6 pb-xxl-5 px-5 pb-4 border-radius-lg d-flex flex-column justify-content-end"
              style="
                background: url(images/login-banner.jpg) center no-repeat;
              "
            >
              <img
                src="/images/login-bg-line.svg"
                alt="pattern-lines"
                class="position-absolute opacity-2 top-0 start-0"
              />

              <h4 class="fs-24 fs-xxl-32 text-white font-weight-bolder mb-2">
                "{{ $tc('attention_is_the_new_currency') }}"
              </h4>
              <p class="mb-0 text-white">
                {{ $tc('the_more_effortless_the_writing_looks') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { get } from 'lodash'
import BaseInput from "@/components/BaseInput.vue"
import AuthService from '@/services/auth_service'

export default {
  components: {
    BaseInput
  },
  props: {

  },
  data() {
    const form = {
      email: "",
    }

    return {
      form,
      errorApi: "",
      apiSuccess: null,
      isSubmit: false,
      isShownDescription: true
    }
  },
  computed: {
  },
  mounted() {
    this.isShownDescription = true;
    this.$el.addEventListener("keypress", function(e) {
      if(e.keyCode === 13) {
        this.submit();
      }
    }.bind(this));
  },
  methods: {
    async submit() {
      const nameValid = this.$refs.email.validate()
      if (nameValid) {
        this.isSubmit = true;
        const { email } = this.form;
        const response = await AuthService.requestResetPwd({ email });
        const data = get(response, 'data', {});
        if (data.code === 200) {
          this.apiSuccess = data.message
        } else {
          this.errorApi = "The email address you entered is invalid"
        }
        this.isSubmit = false;
        this.isShownDescription = false;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}

h4 {
   color: rgb(0, 0, 0);
  //color: rgb(255, 255, 255);
}
</style>
