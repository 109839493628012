<template>
  <div
      :class="[additionClasses, 'col-md-4 col-sm-6 col-12 blog-item d-flex']"
      @click="$router.push(`/courses/${course.slug}`)"
  >
    <div class="item-inner w-100 position-relative d-flex flex-column justify-content-between">
      <!-- image -->
      <div class="thumb"
           :style="`background: url(${course.image}) center center / cover no-repeat;`"
      ></div>
      <div class="student-rate-course" v-if="course.student_review">{{ course.student_review.rate }}<i class="fa fa-star"></i> </div>
      <!-- title -->
      <h4 class="title flex-grow-1">
        <router-link class="color-primary-1 text__truncate -n2" :to="`/courses/${course.slug}`">
          {{ course.name }}
        </router-link>
      </h4>
      <!-- footer -->
      <div class="info d-flex align-items-center">
        <p class="fs-12 fw-400 color-primary-2 author">{{ $tc(course.student_learning.status) }}</p>
        <p class="fs-12 fw500 count color-primary-3">
          {{ course.student_learning_lessons.filter((item) => {return item.status === 'completed'}).length }}
          /
          {{ course.lesson_count }} {{ course.lesson_count > 1 ? $tc('lessons') : $tc('lesson') }}
        </p>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseItem",
  props: {
    course: Object,
    additionClasses: {
      type: String,
      required: false,
    },
  }
}
</script>

<style scoped>

</style>