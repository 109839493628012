<template>
  <section>
    <div class="page-header-dark min-vh-100">
      <div class="container pb-lg-0">
        <div class="row">
          <div
            class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"
          >
            <div class="card card-plain">
              <img :src="api_url + '/get-image/logo'"
                   :width="200"
                   class="px-1 my-5 d-lg-none" />
              <div class="card-header-dark p-1 p-xxl-4 pb-2 pb-xxl-2 mt-5 text-start">
                <h4 class="font-weight-bolder uppercase">{{ $tc('sign_in') }}</h4>
                <p class="mb-0">{{ $tc('enter_your_email_and_pasword') }}</p>
              </div>
              <div class="card-body p-1 p-xxl-4">
                <form role="form">
                  <div class="mb-3">
                    <base-input
                      ref="email"
                      v-model="form.email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      class-name="form-control form-control-lg"
                      errorRequired="Please enter your email"
                      required
                    ></base-input>
                  </div>
                  
                  <div class="mb-3">
                    <base-input
                      ref="password"
                      v-model="form.password"
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      class-name="form-control form-control-lg"
                      errorRequired="Please enter your password"
                      required
                    ></base-input>
                  </div>

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center form-check form-switch">
                      <input
                        v-model="form.remember"
                        class="form-check-input header-dark-btn"
                        type="checkbox"
                        id="rememberMe"
                      />
                      <label
                        class="form-check-label fw-500 ms-2 label-dark" 
                        for="rememberMe"
                      >{{ $tc('remember_me') }}</label>
                    </div>

                    <router-link
                      to="/forgot-password"
                      class="fw-500 label-dark"
                    >{{ $tc('forgot_password') }}</router-link> 
                  </div>

                  <div class="invalid-feedback text-center">
                    <span>
                      {{ errorApi }}
                    </span>
                  </div>

                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-lg bg-gradient-primary btn-lg h6 w-100 mt-4 mb-0 uppercase"
                      :disabled="isSubmit"
                      @click="submit"
                    >
                      {{ $tc('sign_in') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- side image desktop start -->
          <div class="col-6 d-lg-flex d-none h-100 my-auto p-3 position-absolute top-0 end-0 justify-content-center flex-column">
            <div
              class="position-relative h-100 px-xxl-6 pb-xxl-5 px-5 pb-4 border-radius-lg d-flex flex-column justify-content-end"
              :style="{
                background: `url(${api_url}/get-image/login_welcome_image) center no-repeat`,
                backgroundSize: 'contain',
              }"
            >
              <img
                src="/images/login-bg-line.svg"
                alt="pattern-lines"
                class="position-absolute opacity-2 top-0 start-0"
              />

              <h4 class="fs-24 fs-xxl-32 text-white font-weight-bolder mb-2">
                "Always deliver more than expected"
              </h4>
              <p class="mb-0 text-white">
                The earlier you start working on something,<br>
                the earlier you will see results
              </p>
            </div>
          </div>
          <!-- side image desktop end -->
          <!-- bottom image mobile start -->
          <div class="signin-mobile-img col-12 d-lg-none d-block d-flex align-items-center">
            <img :src="api_url + '/get-image/login_welcome_image_mobile'" />
          </div>
          <!-- bottom image mobile end -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { get } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import BaseInput from "@/components/BaseInput.vue"
import AuthService from '@/services/auth_service'
import {VUE_APP_API_HOST} from "@/services/http";

const { mapActions, mapState } = createNamespacedHelpers('auth')

export default {
  components: { BaseInput },
  props: {

  },
  data() {
    const form = {
      password: "",
      email: "",
      remember: false,
    }
    return { form, errorApi: "", isSubmit: false, api_url: VUE_APP_API_HOST}
  },
  computed: {
    ...mapState(['account']),
  },
  mounted() {
    if (this.account.remember) {
      this.form = { ...this.account }
    }
    this.$el.addEventListener("keypress", function(e) {
      if(e.keyCode === 13) {
        this.submit();
      }
    }.bind(this));
  },
  methods: {
    ...mapActions(['requestLoginSuccess']),
    async submit() {
      const nameValid = this.$refs.email.validate()
      const passwordValid = this.$refs.password.validate()
      if (nameValid && passwordValid) {
        this.isSubmit = true
        const { password, email } = this.form
        const response = await AuthService.signIn({ password, email })
        const data = get(response, 'data', {})
        if (data.code === 200) {
          const result = { ...data.data, ...{ account: this.form } }
          this.requestLoginSuccess(result)
          this.$router.push('/')
        } else {
          this.errorApi = "Invalid email address or password, please check again"
        }
        this.isSubmit = false
      }
    },
  },

};
</script>

<style lang="scss" scoped>
h4 {
   color: rgb(0, 0, 0);
  //color: rgb(255, 255, 255);
}

.invalid-feedback {
  display: block;
}

</style>
