import { get } from 'lodash'

import UserService from '../../services/user_service'

const initState = {
  id: "",
  name: "",
  email: "",
  phone: "",
  gender: "",
  birthday: "",
  address: "",
  city: "",
  created_at: "",
  profilePicture: "",
}
const state = {
  accountInfo: initState
}

const actions = {
  requestGetInfo: async ({ commit } ) => {
    const response = await UserService.getInfo()
    const data = get(response, 'data')
    if (data.code === 200) {
      commit('GET_INFO_SUCCESS', data.data)
    } else {
      commit('GET_INFO_FAILED')
    }
  },
  updateUserInfo: async ({ commit }, data ) => {
    commit('GET_INFO_SUCCESS', data)
  }
}

const mutations = {
  ['GET_INFO_SUCCESS'](state, data) {
    state.accountInfo = data
  },
  ['GET_INFO_FAILED'](state) {
    state.errors = initState
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
