import http from './http'

const LessonsService = {
  getList() {
    const api = '/lessons'
    return new http(true).get(api, {})
  },
  getDetail(id) {
    const api = `/lessons/${id}`
    return new http(true).get(api, {})
  },
  updateStatus(id, status = 'completed') {
    const api = `/lessons/${id}/update-status`
    return new http(true).post(api, { status })
  },
}

export default LessonsService
