const state = {
  discussions: [],
}

const getters = {
  getDiscussions(state) {
    return state.discussions;
  },
}

const actions = {
  async createDiscussion(context, data) {
    context.commit('CREATE_DISCUSSION', data);
  },
}

const mutations = {
  ['CREATE_DISCUSSION'](state, data) {
    state.discussions = data;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
