import $store from '../store'

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware (to, from, next) {
  const isAuthenticated = $store.getters['auth/getAuthenticated']
  const isAuthRoute = to.matched.some(item => item.meta.isAuth)

  if (isAuthRoute && isAuthenticated) return next()
  if (isAuthenticated && to.name === 'signin') return next({ name: 'home' })
  if (isAuthRoute) return next({ name: 'signin' })
  next()
}

export function setPageTitleMiddleware (to, from, next) {
  const pageTitle = to.matched.find(item => item.meta.title)

  if (pageTitle) window.document.title = pageTitle.meta.title
  next()
}
