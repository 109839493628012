import http from './http'

const UserService = {
    getInfo() {
        const api = '/auth/me'
        return new http(true).post(api, {})
    },
    updateProfile(form) {
        const formData = new FormData();
        for (var key in form) {
            const val = form[key] === null ? "" : form[key]
            formData.append(key, val);
        }
        const api = '/profile/update'
        return new http(true).post(api, formData)
    },
    updatePassWord(form) {
        const api = '/profile/update-password'
        return new http(true).post(api, form)
    }
}

export default UserService
