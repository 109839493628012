<template>
  <div
    v-if="message"
    :class="[
      'alert alert-dismissible fade show',
      status ? 'alert-success' : 'alert-danger',
    ]"
    role="alert"
  >
    <!-- <span class="alert-icon"><i class="ni ni-like-2"></i></span> -->
    <span class="alert-text">{{ flashMessage }}</span>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="onCloseFlash">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentTime: `${moment(new Date()).format("YYYY/MM/DD HH:mm:ss")}`,
    };
  },
  computed: {
    flashMessage() {
      return this.message
    },
  },
  watch: {
    message() {
      if (this.message) {
        setTimeout(() => {
          this.currentTime = `${moment(new Date()).format("YYYY/MM/DD HH:mm:ss")}`
          this.$emit("onCloseFlash")
        }, 3000);
      }
    },
  },
  methods: {
    onCloseFlash() {
      this.$emit("onCloseFlash");
    },
  },
};
</script>
