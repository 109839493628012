<template>
  <nav aria-label="breadcrumb" class="mb-2 mb-mb-0">
    <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
      <li class="breadcrumb-item">
        <router-link class="link" to="/">{{ $tc('home') }}</router-link>
      </li>
      <li
        v-for="(item, index) in breadcrumbs"
        :key="index"
        class="breadcrumb-item active"
        aria-current="page"
      >
        <router-link class="link" :to="item.link">{{item.name}}</router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('common')

export default {
  name: "Breadcrumbs",
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['breadcrumbs']),
  }
};
</script>
