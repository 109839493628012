<template>
  <main class="main-content mt-0">
    <div class="container position-relative">
      <div
        class="header position-lg-absolute p-3 z-index-2 text-center text-lg-start"
      >
        <router-link class="navbar-brand m-0" to="/">
          <img
            :src="api_url + '/get-image/logo'"
            class="navbar-brand-img h-100"
            :width="200"
            alt="main_logo"
          />
        </router-link>
      </div>
    </div>
    <router-view />
    <div
      class="position-fixed bottom-1 end-1 z-index-2"
    >
      <vsud-snackbar
        v-for="item in notifications"
        :key="item.id"
        :title="item.title"
        date=""
        :description="item.content"
        :icon="{ component: 'ni ni-check-bold', color: 'success' }"
        color="white"
        :close-handler="closeSnackbar"
      />
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import dashboard from "@/assets/js/soft-ui-dashboard.js";
import VsudSnackbar from "@/components/VsudSnackbar.vue"
import {VUE_APP_API_HOST} from "@/services/http";

const { mapState: mapStateNoti, mapActions: mapActionsNoti } = createNamespacedHelpers('notifications')

export default {
  components: {
    VsudSnackbar,
  },
  data() {
    return {
      api_url: VUE_APP_API_HOST
    };
  },
  computed: {
    ...mapStateNoti(['notifications']),
  },
  mounted() {
    dashboard()
  },
  methods: {
    ...mapActionsNoti(['updateNotifications']),
    closeSnackbar() {
      this.updateNotifications([])
    },
  },
};
</script>
<style lang="scss">

</style>
