export default {
  methods: {
    checkInputingEmoji(event) {
      let mayBeEmoji = '';
      event.target.addEventListener('keyup', e => {
        if (e.key === ' ') {
          const input = e.target.value;
          const lastSpaceIndex = input.lastIndexOf(' ');
          const afterLastSpaceIndex = input.slice(0, lastSpaceIndex).lastIndexOf(' ');

          mayBeEmoji = (afterLastSpaceIndex < 0)
            ? mayBeEmoji = input.slice(0, lastSpaceIndex)
            : mayBeEmoji = input.slice(afterLastSpaceIndex + 1, lastSpaceIndex)

          if (mayBeEmoji[0] === ':') {
            const correspondingEmoji = this.emojisCollection.find(emoji => emoji.a.includes(mayBeEmoji));
            if (correspondingEmoji)  {
              const newinput = input.replace(mayBeEmoji, correspondingEmoji.e);
              console.log(newinput);
              e.target.value = newinput;
            }
          }
        }
      })
    },
    checkContentEmoji(content) {
      const contentWords = content.split(' ');
      const mayBeEmojis = [];

      const contentWordsLength = contentWords.length;
      for (let i = 0; i < contentWordsLength; i++) {
        if (contentWords[i][0] === ':') {
          mayBeEmojis.push({
            mayBeEmoji: contentWords[i],
            contentWordsIndex: i
          })
        }
      }

      mayBeEmojis.forEach(mayBeEmoji => {
        this.emojisCollection.forEach(emoji => {
          if (emoji.a.includes(mayBeEmoji.mayBeEmoji)) {
            contentWords[mayBeEmoji.contentWordsIndex] = emoji.e;
          }
        })
      })

      return contentWords.join(' ');
    },
  }
}