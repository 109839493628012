import http from './http'

const NotificationService = {
  getList() {
    const api = '/notification'
    return new http(true).get(api, {})
  },
  createNoti() {
    const api = '/notification/create'
    return new http(true).post(api, { message: "this is test" })
  }
}

export default NotificationService
