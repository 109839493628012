<template>
  <div class="container-fluid pb-4">
    <!-- title start -->
    <div class="mb-4">
      <h2 class="mb-0 page_title">Profile</h2>
    </div>
    <!-- title end -->
    <!-- body start -->
    <div class="maxw-1080 mx-auto">
      <!-- image and name start -->
      <flash-message
        :status="submitStatus"
        :message="submitMessage"
        @onCloseFlash="onCloseFlash"
      ></flash-message>
      <div class="card card-body blur shadow-blur overflow-hidden mb-4">
        <div class="row gx-4">
          <!-- image -->
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                :src="avatar"
                alt="profile_image"
                class="w-100 border-radius-lg profileImageImportant"
              />
              <input type="file" @change="onFileChange" />
            </div>
          </div>
          <!-- name -->
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1 fs-18 fw-700">{{ accountInfo.name }}</h5>
              <p class="mb-0 font-weight-bold text-sm"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- image and name end -->
      <!-- Card Basic Info Start -->
      <div class="card mt-4" id="basic-info">
        <div class="card-header">
          <h5 class="fs-18 fw-700">Basic Information</h5>
        </div>
        <div class="card-body pt-0">
          <div class="row gx-3 gx-sm-4">
            <div class="col-6">
              <label class="form-label">Name</label>
              <BaseInput
                ref="name"
                v-model="form.name"
                :placeholder="form.name"
                class-name="form-control"
                errorRequired="Please enter an name"
                required
              />
            </div>
            <div class="col-6">
              <label class="form-label">{{ $tc('phone_number') }}</label>
              <BaseInput
                ref="phone"
                v-model="form.phone"
                :placeholder="form.phone"
                class-name="form-control"
                errorRequired="Please enter an phone"
                required
              />
            </div>
          </div>
          <div class="row gx-3 gx-sm-4">
            <div class="col-6">
              <label class="form-label mt-4">{{ $tc('gender') }}</label>
              <select
                v-model="form.gender"
                class="form-control"
                name="choices-gender"
                id="choices-gender"
              >
                <option value="1">{{ $tc('male') }}</option>
                <option value="0">{{ $tc('female') }}</option>
              </select>
            </div>
            <div class="col-6">
              <label class="form-label mt-4">{{ $tc('birth_date') }}</label>
              <input
                v-model="form.birthday"
                class="form-control"
                type="date"
                max="9999-12-31"
              />
            </div>
          </div>
          <div class="row gx-3 gx-sm-4">
            <div class="col-sm-6">
              <label class="form-label mt-4">{{ $tc('address') }}</label>
              <BaseInput
                ref="address"
                v-model="form.address"
                type="text"
                :placeholder="form.address"
                class-name="form-control"
                errorRequired="Please enter an address"
              />
            </div>
            <div class="col-sm-6">
              <label class="form-label mt-4">{{ $tc('city') }}</label>
              <BaseInput
                ref="city"
                v-model="form.city"
                type="text"
                :placeholder="form.city"
                class-name="form-control"
                errorRequired="Please enter an city"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label class="form-label mt-4">{{ $tc('zipcode') }}</label>
              <BaseInput
                ref="zipcode"
                v-model="form.zipcode"
                type="text"
                :placeholder="form.zipcode"
                class-name="form-control"
                errorRequired="Please enter an zipcode"
              />
            </div>
            <div class="col-sm-6">
              <button
                class="btn bg-gradient-primary btn-sm float-end mt-6 mb-0 text-uppercase"
                :disabled="isSubmit"
                @click="submitProfile"
              >{{ $tc('update_profile') }}</button>
            </div>
          </div>

        </div>
      </div>
      <!-- Card Basic Info End -->
      <!-- Card Change Password Start -->
      <form class="card mt-4" id="password">
        <div class="card-header">
          <h5 class="fs-18 fw-700">{{ $tc('change_password') }}</h5>
        </div>
        <div class="mx-4">
          <FlashMessage
            :status="submitStatusPwd"
            :message="submitMessagePwd"
            @onCloseFlash="onCloseFlash"
          />
        </div>
        <div class="card-body pt-0">
          <label class="form-label">{{ $tc('current_password') }}</label>
          <div class="form-group">
            <BaseInput
              ref="current_password"
              v-model="formPassWord.current_password"
              type="password"
              class-name="form-control"
              autocomplete="on"
              placeholder="Current Password"
              errorRequired="Please enter an password"
              required
              errorMinLength="Min 8 characters"
              :minLength="8"
            />
          </div>
          <label class="form-label">{{ $tc('new_password') }}</label>
          <div class="form-group">
            <BaseInput
              ref="password"
              v-model="formPassWord.password"
              type="password"
              placeholder="New Password"
              class-name="form-control"
              autocomplete="on"
              errorMinLength="Min 8 characters"
              errorRequired="Please enter an New password"
              required
            />
          </div>
          <label class="form-label">{{ $tc('confirm_new_password') }}</label>
          <div class="form-group">
            <BaseInput
              ref="password_confirmation"
              v-model="formPassWord.password_confirmation"
              type="password"
              placeholder="Confirm New Password"
              autocomplete="on"
              class-name="form-control"
              errorMinLength="Min 8 characters"
              errorRequired="Please enter an Confirm new password"
              required
            />
          </div>
          <h5 class="mt-5 fs-18 fw-700">{{ $tc('passwords_requirements') }}</h5>
          <p class="text-muted mb-2 fs-14 fw-700">
            {{ $tc('please_follow_this_guide_for_a_strong_password') }}:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start fs-12">
            <li>
              <span class="fw-700">{{ $tc('one_special_character') }}</span>
            </li>
            <li>
              <span class="fw-700">{{ $tc('min_8_characters') }}</span>
            </li>
            <li>
              <span class="fw-700">{{ $tc('one_number_2_are_recommended') }}</span>
            </li>
            <li>
              <span class="fw-700">{{ $tc('change_it_often') }}</span>
            </li>
          </ul>
          <button
            class="btn bg-gradient-primary btn-sm float-end mt-6 mb-0 text-uppercase"
            type="button"
            :disabled="isSubmit"
            @click="submitChangePwd"
          >{{ $tc('update_password') }}</button>
        </div>
      </form>
      <!-- Card Change Password End -->
    </div>
    <!-- body end -->
    <!-- footer start -->
    <Footer />
    <!-- footer end -->
  </div>
</template>

<script>
// improt components
import BaseInput from "@/components/BaseInput.vue";
import FlashMessage from "@/components/flash-message";
import Footer from "../../layouts/Footer.vue";
// import libs
import { createNamespacedHelpers } from 'vuex';
import $store from '@/store';
import { isEmpty, get } from 'lodash';
// import services
import UserService from '@/services/user_service';

const { mapState: mapStateUser } = createNamespacedHelpers('user');
const { mapActions: mapActionsCommon } = createNamespacedHelpers('common');

export default {
  components: { Footer, BaseInput, FlashMessage },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        gender: 1,
        birthday: new Date().toISOString().slice(0,10),
        address: "",
        city: "",
        zipcode: "",
        avatar: ""
      },
      formPassWord: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      avatar: "/images/loading.gif",
      submitStatus: null,
      submitMessage: "",
      submitStatusPwd: null,
      submitMessagePwd: "",
      isSubmit: false
    };
  },
  watch: {
    accountInfo(val) {
      if (!isEmpty(val)) {
        this.updateDefaultData(val)
      }
    }
  },
  computed: {
    ...mapStateUser(['accountInfo']),
  },
  mounted() {
    if (!isEmpty(this.accountInfo)) {
      this.updateDefaultData(this.accountInfo)
    }
    this.updateBreadcrumbs([{ name: 'Profile', link: "/profile" }])
  },
  methods: {
    ...mapActionsCommon(['updateBreadcrumbs']),
    async submitProfile() {
      const nameValid = this.$refs.name.validate();
      const phoneValid = this.$refs.phone.validate();
      if (nameValid && phoneValid) {
        const response = await UserService.updateProfile(this.form);
        const data = get(response, 'data', {});
        if (data.code === 200) {
          $store.dispatch('user/updateUserInfo', data.data);
          this.submitStatus = true;
          this.submitMessage = 'update profile success';
        } else {
          this.errorApiProfile = data.error;
        }
      }
    },
    async submitChangePwd() {
      const currentPasswordValid = this.$refs.current_password.validate()
      const passwordValid = this.$refs.password.validate()
      const passwordConfirmationValid = this.$refs.password_confirmation.validate()

      if (currentPasswordValid && passwordValid && passwordConfirmationValid) {
        const response = await UserService.updatePassWord(this.formPassWord)
        const data = get(response, 'data', {})
        if (data.code === 200) {
          this.submitStatusPwd = true
          this.submitMessagePwd = 'update password success'
          this.$refs.current_password.resetValidate()
          this.$refs.password.resetValidate()
          this.$refs.password_confirmation.resetValidate()
          this.formPassWord = {
            current_password: "",
            password: "",
            password_confirmation: "",
          }
        } else {
          this.submitStatusPwd = false
          this.submitMessagePwd = data.error
        }
      }
    },
    updateDefaultData(data) {
      const {
        name,
        phone,
        gender,
        birthday,
        address,
        city,
        zipcode,
        profilePicture: avatar,
      } = data

      const date = new Date(birthday || this.form.birthday).toISOString().slice(0,10)

      this.form = { name, phone, gender, birthday: date, address, city, zipcode, avatar };
      this.avatar = avatar || this.avatar;
    },
    onCloseFlash() {
      this.submitStatus = null
      this.submitMessage = ''
      this.submitStatusPwd = null
      this.submitMessagePwd = ""
    },
    onFileChange(e) {
      const file = e.target.files[0]
      if (file) {
        this.avatar = URL.createObjectURL(file)
        this.form.avatar = file
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
}

.avatar img {
  width: 100%;
  height: 100%;
  border: 1px solid #E0E1E2;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.avatar input {
  width: 100%;
  height: 95px;
  opacity: 0;
  position: absolute;
  top: -25px;
  left: 0;
  z-index: 5;
}

.avatar input:hover {
  cursor: pointer;
}

.avatar:before {
  content: "+";
  width: 100%;
  height: 100%;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  z-index: 3;
}

.avatar:hover:before {
  opacity: 1;
}
</style>
