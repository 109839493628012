<template>
  <div class="g-sidenav-show">
    <component :is="layout"></component>
    <div v-html="customStyle"></div>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";

const default_layout = 'app';
import AppLayout from './layouts/AppLayout';
import SignInLayout from './layouts/SignInLayout';
import ConfigService from "@/services/config_service";


export default {
  components: {
    'app-layout': AppLayout,
    'signin-layout': SignInLayout,
    'empty-layout': EmptyLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    }
  },
  data() {
    return {
      customStyle: ""
    }
  },
  mounted() {
    this.getBackendCss();
  },
  methods : {
    async getBackendCss() {
      const response = await ConfigService.getStyle();
      const data = response.data;
      this.customStyle = '<style>' + data + '</style>'
    }
  }
}
</script>

<style>
</style>
