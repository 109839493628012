import axios from 'axios';
import $store from '../store'
import $router from '../router'
import { get } from 'lodash'

export const VUE_APP_API_URL = `${process.env.VUE_APP_API_HOST}/api/`
export const VUE_APP_API_HOST = `${process.env.VUE_APP_API_HOST}`

class http {
  constructor(isAuth) {
    this.isAuth = isAuth
    this.instance = axios.create({
      baseURL: VUE_APP_API_URL
    })

    this.defaultOptions = {
      headers: {
        'content-type': 'application/json'
      }
    }
    return this.init()
  }

  init() {
    if (this.isAuth) {
      let accessToken = $store.getters['auth/getAccessToken']
      this.instance.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${accessToken}`
        return config
      })
    }
    this.instance.defaults.baseURL = VUE_APP_API_URL
    // Add a response interceptor.
    this.instance.interceptors.response.use(
      response => { return response},
      error => {
        // Do something with request error
        const status = get(error, 'response.status')
        if (this.isAuth && status === 401) {
          $store.dispatch('auth/resetAuthData')
          $router.push('/signin')
        }
        return get(error, 'response', {})
        // return Promise.reject(error);
      }
    )

    return {
      get: (url, options = {}) => this.instance.get(url, { ...this.defaultOptions, ...options }),
      post: (url, data, options = {}) => this.instance.post(url, data, { ...this.defaultOptions, ...options }),
      put: (url, data, options = {}) => this.instance.put(url, data, { ...this.defaultOptions, ...options }),
      delete: (url, options = {}) => this.instance.delete(url, { ...this.defaultOptions, ...options }),
    }
  }
}

export default http
