<template>
  <div>
    <button
      v-show="false"
      type="button"
      class="btn bg-gradient-primary mx-1"
      data-bs-toggle="modal"
      data-bs-target="#ThanksModal"
      ref="btnThanksModal"
    >{{ $tc('confirm') }}</button>
    <div
      class="modal fade modal-alert modals-custom"
      id="ThanksModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="ratio ratio-1x1">
              <img src="/images/SmileyWink.png" />
            </div>
            <p class="title">{{ $tc('thank_you_for_your_feedback') }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-success bg-gradient-primary"
              @click="navigateToCoursesPage"
            >{{ $tc('go_to_courses') }}</button>
            <button
              type="button"
              class="btn btn-cancel bg-gradient-dark"
              data-bs-dismiss="modal"
            >{{ $tc('stay_in_this_course') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "btnThanksModal",
  props: {
    doQuiz: {
      type: Function,
    }
  },
  methods: {
    toggleModal () {
      this.$refs.btnThanksModal.click()
    },
    navigateToCoursesPage() {
      this.$router.push('/my-course');
    },
  }
};
</script>
