// import $router from '../../router'

const state = {
  account: {
    email: "",
    password: "",
    remember: false,
  },
  accessToken: '',
  isAuthenticated: false,
  errors: []
}

const getters = {
  getAccessToken: (state) => {
    return state.accessToken
  },
  getAuthenticated: (state) => {
    return state.isAuthenticated
  },
}

const actions = {
  requestLoginSuccess: async ({ commit }, data ) => {
    commit('AUTH_SUCCESS', data)
    commit('SAVE_TOKEN', data)
  },
  requestLogout: async ({ commit }) => {
    commit('DESTROY_TOKEN')
    location.reload()
  },
  resetAuthData: ({ commit }) => {
    commit('DESTROY_TOKEN')
  }
}

const mutations = {
  ['AUTH_SUCCESS'](state, data) {
    state.account = data.account
    state.errors = []
  },
  ['SAVE_TOKEN'](state, data) {
    state.accessToken = data.access_token
    state.isAuthenticated = true
  },
  ['DESTROY_TOKEN'](state) {
    state.accessToken = ''
    state.isAuthenticated = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
